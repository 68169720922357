<template>
  <div>
    <div class="order-background" :class="[bgTrigger ? '' : 'hideOrder']"></div>
    <Order
      @closed="bgTrigger = isOrderShow = false"
      :currentFood="orderFood"
      :trigger="isOrderShow"
      :store="store"
      @saved="addItem"
    ></Order>
    <TakeAwayTime
      class="time-display"
      :class="[isTimeShow ? 'flexTime' : 'hideTime']"
      :takeTime="takeTime"
      :workDaysArr="workDaysArr"
      :waitingSetting="waitingSetting"
      :defaultDate="selectedDate"
      :defaultTime="selectedTime"
      :trigger="isTimeShow"
      :hasIntervalCounts="hasIntervalCounts"
      @closed="isTimeShow = false"
      @saved="changeTime"
    ></TakeAwayTime>
    <div class="menu">
      <div class="menu-nav">
        <img class="menu-logo" src="../imgs/Logo.png" alt="UShow" />
        <div v-if="isMultiLanguage" class="language-selector">
          <div class="language-list-button" @click="openLanguageList">
            <i class="fas fa-globe"></i> {{ currentLang.lang }}
          </div>
          <div class="language-list">
            <div
              v-if="isMultiLanguage"
              class="language-option first-option"
              @click="clickLanguage('zh')"
            >
              中文 (ZH)
            </div>
            <div
              v-if="store.HasEnglish"
              class="language-option"
              @click="clickLanguage('en')"
            >
              English (EN)
            </div>
            <div
              v-if="store.HasJapanese"
              class="language-option"
              @click="clickLanguage('jp')"
            >
              日本語 (JP)
            </div>
            <div
              v-if="store.HasKorean"
              class="language-option"
              @click="clickLanguage('kr')"
            >
              한국어 (KR)
            </div>
            <div
              v-if="store.HasFrench"
              class="language-option"
              @click="clickLanguage('fr')"
            >
              Français (FR)
            </div>
          </div>
        </div>
        <a class="menu-member" @click="memberClick">
          <img class="menu-member-icon" src="../imgs/user2.png" alt="" />
          <p class="menu-member-text">{{ memberText }}</p></a
        >
      </div>
      <div class="menu-container">
        <div v-if="store.ImageURL" class="menu-store-img-container">
          <img class="menu-store-img" :src="store.ImageURL" alt="店家" />
        </div>
        <div v-else class="image-empty"></div>
        <div class="content-main">
          <div class="menu-store">
            <div class="menu-store-title">
              <div class="menu-store-title-left">
                <div
                  class="menu-store-work-icon"
                  :class="[store.IsOpen ? 'opened' : 'closed']"
                >
                  <i class="fas fa-circle"></i>
                </div>

                <span class="menu-store-name">{{ store.StoreName }}</span>
              </div>
              <div class="bold pr-2">
                <p class="opened" v-if="store.IsOpen">{{ $t("menu.open") }}</p>
                <p class="closed" v-else>{{ $t("menu.closed") }}</p>
              </div>
            </div>
            <div v-show="store.IsShowIntroduction" class="menu-store-memo">
              <p>{{ store.Introduction }}</p>
            </div>

            <div class="menu-store-group my-1">
              <i class="fas fa-phone-alt"></i>
              <a class="menu-phone-link" :href="phoneLink">{{ store.Phone }}</a>
            </div>
            <div class="menu-store-group my-1">
              <i class="fas fa-map-marker-alt"></i>
              <p>{{ store.Address }}</p>
            </div>
            <div
              class="menu-store-group pointer my-1"
              @click="isShowWorkDays = !isShowWorkDays"
            >
              <i class="far fa-calendar-alt"></i>
              <p>{{ $t("menu.openinghours") }}</p>
              <div
                class="workDayDropDown transition"
                :class="[isShowWorkDays ? '' : 'rotate']"
              >
                <i class="fas fa-chevron-up"></i>
              </div>
            </div>
            <div
              class="menu-workdays transition"
              :class="[isShowWorkDays ? '' : 'menu-workdays-collapse']"
            >
              <hr class="mt-1" />
              <div
                class="menu-workday-group"
                v-for="(day, index) in workDaysArr"
                :key="index"
              >
                <p class="menu-workday-title">{{ day.Name }}</p>
                <p v-for="time in day.Times" :key="time">{{ time }}</p>
              </div>
              <hr class="mb-1" />
            </div>
            <div
              v-if="workDaysArr.length > 0"
              class="menu-store-group mt-2 mb-3"
            >
              <div
                :class="[hasEdit ? 'pointer' : '']"
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  border: 1px solid #16418f;
                  background-color: #c9dcff47;
                  border-radius: 5px 5px 5px 5px;
                  padding-left: 15px;
                  width: 100%;
                "
                @click="editPickUpTime"
              >
                <div style="padding: 15px 0px">
                  <span class="ml-1" style="vertical-align: middle"
                    ><i
                      class="fas fa-shopping-bag"
                      style="color: #16418f"
                    ></i></span
                  ><span class="ml-2" style="vertical-align: middle">{{
                    $t("menu.preorder")
                  }}</span>
                  <span class="ml-1" style="font-size: 13px">|</span>
                  <span class="ml-1" style="vertical-align: middle">{{
                    expectTakeTime
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="menu-kind-bar">
            <ul class="menu-kind-bar-main ul-scroll" id="foodKindList">
              <li
                :class="
                  currentFoodKindId == foodKindGroup.FoodKind.FoodKindId
                    ? 'active'
                    : ''
                "
                :id="foodKindGroup.FoodKind.FoodKindId + '_li'"
                v-for="foodKindGroup in foodKindGroups"
                :key="foodKindGroup.FoodKind.FoodKindId"
                @click="changeFoodKind(foodKindGroup.FoodKind.FoodKindId)"
              >
                {{ foodKindGroup.FoodKind.DisplayName }}
                <div
                  v-if="
                    checkCartItems(
                      foodKindGroup,
                      foodKindGroup.FoodKind.FoodKindId
                    )
                  "
                  class="menu-group-kind-current-Qty"
                >
                  {{
                    checkCartItems(
                      foodKindGroup,
                      foodKindGroup.FoodKind.FoodKindId
                    )
                  }}
                </div>
              </li>
            </ul>
          </div>

          <div class="mt-3" style="position: relative" :key="renderKey">
            <div class="menu-display-mode">
              <div
                class="menu-display-button"
                :class="isGrid ? 'grid' : ''"
              ></div>
              <div
                class="menu-display-list"
                :class="isGrid ? '' : 'using'"
                @click="isGrid = false"
              >
                <i class="fas fa-list-ul"></i>
              </div>
              <div
                class="menu-display-grid"
                :class="isGrid ? 'using' : ''"
                @click="isGrid = true"
              >
                <i class="fas fa-th-large"></i>
              </div>
            </div>
            <div
              class="menu-group"
              v-for="foodKindGroup in foodKindGroups"
              :key="foodKindGroup.FoodKind.FoodKindId"
              :id="foodKindGroup.FoodKind.FoodKindId"
            >
              <div class="menu-group-kind">
                <div class="menu-group-kind-left"></div>
                <div class="menu-group-kind-name">
                  {{ foodKindGroup.FoodKind.FoodKindName }}
                </div>
                <div class="menu-group-kind-right"></div>
              </div>
              <div class="menu-group-container" :class="isGrid ? 'grid' : ''">
                <div
                  class="menu-group-food"
                  :class="isGrid ? 'grid' : ''"
                  v-for="food in foodKindGroup.Foods"
                  :key="food.FoodId"
                  @click="foodClick($event, food)"
                >
                  <div
                    v-if="food.Stock != null && food.Stock > 0"
                    class="menu-group-food-stock"
                  >
                    {{ $t("menu.left") + food.Stock }}
                  </div>
                  <div
                    class="menu-group-food-text"
                    :style="isGrid ? '' : 'margin-right: 10px;'"
                  >
                    <div>
                      <p
                        class="menu-group-food-name"
                        :class="[
                          food.Stock <= 0 && food.Stock != null
                            ? 'sell-out'
                            : '',
                        ]"
                        :style="isGrid ? 'margin-top: 5px;' : ''"
                      >
                        {{ food.DisplayName }}
                      </p>
                      <p
                        class="menu-group-food-memo"
                        :class="[
                          food.Stock <= 0 && food.Stock != null
                            ? 'sell-out'
                            : '',
                        ]"
                        :style="isGrid ? 'height: 18px;' : ''"
                      >
                        {{ food.Introduction }}
                      </p>
                    </div>

                    <div class="d-flex">
                      <p
                        class="menu-group-food-price"
                        :class="[
                          food.Stock <= 0 && food.Stock != null
                            ? 'sell-out'
                            : '',
                        ]"
                      >
                        ${{ food.SalPrice }}
                      </p>
                      <p
                        v-if="store.HasFoodUnit"
                        class="menu-group-food-unit"
                        :class="[
                          food.Stock <= 0 && food.Stock != null
                            ? 'sell-out'
                            : '',
                        ]"
                      >
                        {{ food.Unit }}
                      </p>
                    </div>
                  </div>
                  <div class="menu-list">
                    <div
                      class="menu-list-thumbnail-container"
                      :style="isGrid ? 'width: 100%; height: 146px;' : ''"
                    >
                      <img
                        v-if="food.ImageFileName"
                        class="menu-list-thumbnail"
                        :src="food.ImageFileName"
                        alt="商品"
                      />
                      <div
                        v-if="food.Stock <= 0 && food.Stock != null"
                        class="sell-out-filter"
                        :class="[!food.ImageFileName ? 'non-img' : '']"
                      >
                        {{ $t("menu.soldout") }}
                      </div>
                    </div>
                    <div v-if="food.Stock > 0 || food.Stock == null">
                      <div
                        class="cart-item-qty plus-target"
                        :class="[food.CurrentQty > 0 ? 'has-cart-item' : '']"
                        :data-foodid="food.FoodId"
                      >
                        <div class="cart-item-qty-close plus-target">
                          <div
                            class="plus-target"
                            :class="[food.CurrentQty > 0 ? '' : 'hide']"
                          >
                            {{ food.CurrentQty }}
                          </div>
                          <div
                            class="plus-target"
                            :class="[food.CurrentQty == 0 ? '' : 'hide']"
                          >
                            <i class="fas fa-plus plus-target"></i>
                          </div>
                        </div>
                        <div class="cart-item-qty-open plus-target">
                          <div
                            class="cart-item-qty-remove plus-target"
                            :class="[food.CurrentQty == 1 ? '' : 'hide']"
                            :data-foodid="food.FoodId"
                            @click="removeItem($event, food)"
                          >
                            <i class="fas fa-trash-alt plus-target"></i>
                          </div>
                          <div
                            class="cart-item-qty-minus plus-target"
                            :class="[food.CurrentQty > 1 ? '' : 'hide']"
                            :data-foodid="food.FoodId"
                            @click="minusQty($event, food)"
                          >
                            <i class="fas fa-minus plus-target"></i>
                          </div>
                          <div
                            class="cart-item-qty-count plus-target"
                            @click="$event.stopPropagation()"
                          >
                            {{ food.CurrentQty }}
                          </div>
                          <div
                            class="cart-item-qty-plus plus-target"
                            :class="[
                              food.CurrentQty == food.Stock ? 'limited' : '',
                            ]"
                            :data-foodid="food.FoodId"
                            @click="addQty($event, food)"
                          >
                            <i class="fas fa-plus plus-target"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="menu-list-thumbnail-container">
                    <img
                      v-if="food.ImageFileName"
                      class="menu-list-thumbnail"
                      :src="food.ImageFileName"
                      alt="商品"
                    />
                    <div
                      v-if="food.Stock <= 0 && food.Stock != null"
                      class="sell-out-filter"
                      :class="[!food.ImageFileName ? 'non-img' : '']"
                    >
                      已售完
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="menu-cart">
        <button
          v-if="cart.Counts > 0"
          class="btn btn-full btn-border flex space-between items-center"
          @click="goCheckout"
        >
          <div class="menu-cart-counts">
            <p>{{ cart.Counts + $t("menu.cartitem") }}</p>
          </div>
          <p>{{ $t("menu.cart") }}</p>
          <p class="menu-cart-total">${{ cart.Total }}</p>
        </button>
        <button v-else class="btn btn-full btn-border">
          <p>{{ $t("menu.pleasechooseyourmeal") }}</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Order from "../components/Order.vue";
import TakeAwayTime from "../components/TakeAwayTime.vue";
import liff from "@line/liff";

export default {
  data() {
    return {
      cart: this.$cookie.cart.getDefault(),
      isOrderShow: false,
      orderFood: {},
      store: {},
      foodKindGroups: [],
      foodKinds: [],
      currentFoodKindId: "",
      isShowWorkDays: false,
      nowToken: this.$cookie.member.getToken(),
      isTimeShow: false,
      takeTime: {
        dateTypes: [],
        timeTypes: [],
        currentNum: 0,
      },
      expectTakeTime: "",
      workDaysArr: [],
      waitingSetting: {},
      weeks: this.$enum.weekType.getAll(),
      selectedDate: {
        value: 0,
        day: {
          value: 0,
          text: "",
          word: "",
        },
        text: "",
      },
      selectedTime: {
        value: 0,
        text: "",
        time: "",
        leng: 0,
      },
      restDates: 0,
      hasEdit: true,
      isEdit: true,
      renderKey: 0,
      countdownTimeout: null,
      bgTrigger: false,
      currentLang: {},
      isGrid: false,
    };
  },
  components: {
    Order,
    TakeAwayTime,
  },
  computed: {
    isMultiLanguage() {
      return (
        this.store.HasEnglish ||
        this.store.HasJapanese ||
        this.store.HasKorean ||
        this.store.HasFrench
      );
    },
    phoneLink() {
      return `tel:${this.store.Phone}`;
    },
    memberText() {
      if (this.nowToken) {
        if (this.$cookie.customer.isStoreCustomer(this.$route.params.keyid)) {
          const customer = this.$cookie.member.get();
          return customer.Name;
        } else {
          return this.$t("menu.joinstore");
        }
      } else {
        return this.$t("menu.login");
      }
    },
    hasIntervalCounts() {
      return this.store.OnlineOrderIntervalCounts > 0;
    },
  },
  watch: {
    isGrid(value) {
      localStorage.setItem("isGrid", value);
    },
    currentLang(value) {
      this.$i18n.locale = value.lang.toLowerCase();
      this.weeks = this.$enum.weekType.getAll();
      this.workDaysArr.forEach((workDay) => {
        if (workDay.RestKeyword) workDay.Times[0] = this.$t("menu.closedday");
        workDay.Name = this.$enum.weekType.getByCht(workDay.Text).text;
      });
      this.$api.stores
        .getStatus(this.$route.params.keyid, {
          language_type: this.currentLang.value,
        })
        .then((data) => {
          this.store = data;
          this.$store.dispatch("updateLoading", false);
        })
        .catch((error) => {
          this.$swal(error.data.Message);
        });
      this.setExpectTakeTime(false);
      this.takeTime.timeTypes.forEach((timeType) => {
        if (timeType.text.length > 7) {
          timeType.text.slice(0, 8);
          timeType.text += ` (${this.$t("menu.thistimeslotisfullybooked")})`;
        }
      });
      if (this.selectedTime.text.length == 0) {
        this.expectTakeTime = this.$t("menu.closedtoday");
      }
    },
    isOrderShow(value) {
      if (value) {
        // $(".order-display").css("overflow", "auto");
        $("body").css("overflow", "hidden");
        window.addEventListener("click", this.clickOnBackground);
      } else {
        // $(".order-display").css("overflow", "hidden");
        $("body").css("overflow", "auto");
        window.removeEventListener("click", this.clickOnBackground);
      }
    },
    isTimeShow(value) {
      if (value) {
        $(".time-display").css("overflow", "auto");
        $("body").css("overflow", "hidden");
      } else {
        $(".time-display").css("overflow", "hidden");
        $("body").css("overflow", "auto");
      }
    },
    foodKinds(value) {
      if (value.length > 0) this.currentFoodKindId = value[0].FoodKindId;
      else this.currentFoodKindId = "";
    },
    currentFoodKindId(value) {
      $("#foodKindList").animate(
        {
          scrollLeft: $(`#${value}_li`).offset().left - 20,
        },
        100
      );
    },
  },
  methods: {
    checkCartItems(foodKindGroup, kindId) {
      let kindArray = foodKindGroup.Foods.filter((item) => {
        return item.FoodKindId == kindId;
      });
      let totalQty = 0;
      kindArray.forEach((item) => {
        totalQty += item.CurrentQty;
      });
      if (totalQty > 0) {
        return totalQty;
      }
    },
    setCurrentLanguage(lang) {
      localStorage.setItem("currentLang", lang);
      this.$store.dispatch("setLanguage", lang);
      this.currentLang = this.$enum.langType.getByLang(lang);
      this.$api.menus
        .get({
          eat_type: 0,
          language_type: this.currentLang.value,
        })
        .then((data) => {
          this.foodKindGroups = data;
          this.setFoodsQty();
          this.$store.dispatch("updateLoading", false);
        });
    },
    clickLanguage(lang) {
      if (this.currentLang.lang.toLowerCase() != lang) {
        this.setCurrentLanguage(lang);
      }
    },
    openLanguageList(e) {
      const btn = e.currentTarget;
      const list = document.querySelector(".language-list");
      btn.classList.add("active");
      list.classList.add("active");
      setTimeout(() => {
        document.addEventListener("click", this.closeLanguageList);
      }, 0);
    },
    closeLanguageList() {
      const btn = document.querySelector(".language-list-button");
      const list = document.querySelector(".language-list");
      btn.classList.remove("active");
      list.classList.remove("active");
      setTimeout(() => {
        document.removeEventListener("click", this.closeLanguageList);
      }, 0);
    },
    clickOnBackground(e) {
      if (e.target.classList.contains("order-background")) {
        this.bgTrigger = false;
        this.isOrderShow = false;
      }
    },
    setFoodsQty() {
      this.foodKindGroups.forEach((foodKindGroup) => {
        foodKindGroup.Foods.forEach((food) => {
          let qty = 0;
          let sameIdItems = this.cart.Items.filter((item) => {
            return item.FoodId == food.FoodId;
          });
          sameIdItems.forEach((item) => {
            qty += item.Qty;
          });
          food.CurrentQty = qty;
        });
      });
    },
    countCartItem(food) {
      let qty = 0;
      let cartItems = this.cart.Items.filter((item) => {
        return item.FoodId == food.FoodId;
      });
      cartItems.forEach((cartItem) => {
        qty += cartItem.Qty;
      });
      return qty;
    },
    changeTime(date, time) {
      this.selectedDate = date;
      this.selectedTime = time;
      this.setExpectTakeTime(true);
      this.isTimeShow = false;
    },
    editPickUpTime() {
      if (!this.hasEdit) {
        return;
      }
      this.restDates = 0;
      this.setReserveDatas(this.isEdit);
      this.isTimeShow = true;
    },
    addItem(cartItem) {
      this.cart = this.$cookie.cart.addItem(this.$route.params.keyid, cartItem);
      this.setFoodsQty();
      this.renderKey++;
      this.bgTrigger = false;
      this.isOrderShow = false;
    },
    addQty(e, food) {
      clearTimeout(this.countdownTimeout);
      e.stopPropagation();
      if (food.CurrentQty == food.Stock) {
        return;
      }
      const ect = document.querySelector(
        `.cart-item-qty[data-foodid="${e.currentTarget.dataset.foodid}"]`
      );
      let item = this.cart.Items.find((i) => {
        return i.FoodId == food.FoodId;
      });
      this.cart = this.$cookie.cart.addQty(
        this.$route.params.keyid,
        item.ItemId
      );
      this.setFoodsQty();
      this.$nextTick(() => {
        this.clickPlusBtn(ect);
      });
    },
    minusQty(e, food) {
      clearTimeout(this.countdownTimeout);
      e.stopPropagation();
      const ect = document.querySelector(
        `.cart-item-qty[data-foodid="${e.currentTarget.dataset.foodid}"]`
      );
      let item;
      item = this.cart.Items.find((i) => {
        return i.FoodId == food.FoodId && i.Memo == "";
      });
      if (!item) {
        item = this.cart.Items.find((i) => {
          return i.FoodId == food.FoodId;
        });
      }
      this.cart = this.$cookie.cart.minusQty(
        this.$route.params.keyid,
        item.ItemId
      );
      this.setFoodsQty();
      this.$nextTick(() => {
        this.clickPlusBtn(ect);
      });
    },
    removeItem(e, food) {
      clearTimeout(this.countdownTimeout);
      e.stopPropagation();
      let item;
      item = this.cart.Items.find((i) => {
        return i.FoodId == food.FoodId && i.Memo == "";
      });
      if (!item) {
        item = this.cart.Items.find((i) => {
          return i.FoodId == food.FoodId;
        });
      }
      this.cart = this.$cookie.cart.removeItem(
        this.$route.params.keyid,
        item.ItemId
      );
      const group = document.querySelector(
        `.cart-item-qty[data-foodid="${food.FoodId}"]`
      );
      const qtyBtns = group.querySelector(".cart-item-qty-open");
      const plusBtn = group.querySelector(".cart-item-qty-close");
      group.classList.remove("active");
      qtyBtns.classList.remove("active");
      plusBtn.classList.remove("active");

      this.setFoodsQty();
    },
    clickPlusBtn(ect) {
      const group = ect;
      const qtyBtns = group.querySelector(".cart-item-qty-open");
      const plusBtn = group.querySelector(".cart-item-qty-close");
      const groups = document.querySelectorAll(".cart-item-qty");
      const groupsArray = Array.from(groups);
      groupsArray.forEach((g) => {
        if (g.dataset.foodid != group.dataset.foodid) {
          const btns = g.querySelector(".cart-item-qty-open");
          const btn = g.querySelector(".cart-item-qty-close");
          g.classList.remove("active");
          btns.classList.remove("active");
          btn.classList.remove("active");
        }
      });

      if (!group.classList.contains("active")) {
        group.classList.add("active");
        setTimeout(() => {
          plusBtn.classList.add("active");
          qtyBtns.classList.add("active");
          this.countdownTimeout = setTimeout(() => {
            group.classList.remove("active");
            qtyBtns.classList.remove("active");
            plusBtn.classList.remove("active");
          }, 5000);
        }, 200);
      } else {
        this.countdownTimeout = setTimeout(() => {
          group.classList.remove("active");
          qtyBtns.classList.remove("active");
          plusBtn.classList.remove("active");
        }, 5000);
      }
    },
    foodClick(e, food) {
      const eventTarget = e.target;
      const eventCurrentTarget = e.currentTarget;
      const ect = eventCurrentTarget.querySelector(
        `.cart-item-qty[data-foodid="${food.FoodId}"]`
      );
      const vm = this;
      if (food.Stock <= 0 && food.Stock != null) return;
      clearTimeout(this.countdownTimeout);
      this.$api.foods
        .get(food.FoodId, { language_type: this.currentLang.value })
        .then((data) => {
          this.orderFood = data;
          if (
            this.orderFood.FoodCombos.length == 0 &&
            this.orderFood.FoodTastes.length == 0 &&
            (eventTarget.nodeName == "path" ||
              eventTarget.classList.contains("plus-target"))
          ) {
            if (!this.cart.Items.find((item) => food.FoodId == item.FoodId)) {
              let newItem = {
                Qty: 1,
                SalPrice: 0,
                Total: 0,
                Tastes: [],
                Combos: [],
                Memo: "",
              };
              newItem.ItemId = this.$utils.uuid.get();
              newItem.FoodKindId = this.orderFood.Food.FoodKindId;
              newItem.FoodId = this.orderFood.Food.FoodId;
              newItem.FoodName = this.orderFood.Food.FoodName;
              newItem.DisplayName = this.orderFood.Food.DisplayName;
              newItem.OriginalPrice = this.orderFood.Food.SalPrice;
              newItem.SalPrice = this.orderFood.Food.SalPrice;
              this.$cookie.cart.calculateCombo(newItem);
              this.cart = this.$cookie.cart.addItem(
                this.$route.params.keyid,
                newItem
              );
              this.setFoodsQty();
            }
            this.$nextTick(() => this.clickPlusBtn(ect));
          } else {
            const cart = this.$cookie.cart.get(this.$route.params.keyid);
            let count = 0;
            cart.Items.forEach((item) => {
              if (item.FoodId == food.FoodId) {
                count = count + item.Qty;
              }
            });
            if (food.Stock <= count && food.Stock != null) {
              this.$swal("已達庫存上限");
              this.$store.dispatch("updateLoading", false);
              return;
            }
            this.bgTrigger = true;
            this.isOrderShow = true;
          }
          this.$store.dispatch("updateLoading", false);
        })
        .catch((error) => {
          vm.$swal(error.data.Message);
          vm.bgTrigger = false;
        });
    },
    memberClick() {
      const token = this.$cookie.member.getToken();
      if (token) {
        if (this.$cookie.customer.isStoreCustomer(this.$route.params.keyid)) {
          this.$router.push({
            name: "Member",
          });
        } else {
          this.$router.push({
            name: "Join",
          });
        }
      } else {
        const vm = this;
        if (liff.isInClient()) {
          vm.$api.members
            .searchLineUserId(vm.store.DeviceId, vm.$store.state.lineUserId)
            .then((data) => {
              vm.$cookie.member.set(data);
              vm.$cookie.customer.set(data);
              vm.nowToken = vm.$cookie.member.getToken();
            })
            .catch(() => {
              vm.$router.push({
                name: "Login",
              });
            });
        } else {
          this.$router.push({
            name: "Login",
          });
        }
      }
    },
    goCheckout() {
      if (this.cart.Counts == 0) {
        this.$swal(this.$t("menu.pleasechooseyourmeal"));
        return;
      }
      if (this.$cookie.member.getToken() && this.$cookie.member.get()) {
        this.$router.push(`/${this.$route.params.keyid}/checkout`);
      } else {
        this.$router.push({ name: "Login" });
      }
    },
    changeFoodKind(id) {
      window.removeEventListener("scroll", this.onScroll);
      this.currentFoodKindId = id;
      const vm = this;
      $("html, body").animate(
        {
          scrollTop: $(`#${id}`).offset().top - 120,
        },
        100,
        function () {
          window.addEventListener("scroll", vm.onScroll);
        }
      );
    },
    onScroll(e) {
      let windowTop = e.target.documentElement.scrollTop;
      const vm = this;
      this.foodKindGroups.forEach((foodKindGroup) => {
        const positionToTop = $(
          `#${foodKindGroup.FoodKind.FoodKindId}`
        ).offset().top;
        if (positionToTop - 120 < windowTop) {
          vm.currentFoodKindId = foodKindGroup.FoodKind.FoodKindId;
        }
      });
    },
    setExpectTakeTime(isChange) {
      if (this.hasEdit) {
        let oldDate = JSON.parse(
          localStorage.getItem(`selectedDate.${this.$route.params.keyid}`)
        );
        let oldTime = JSON.parse(
          localStorage.getItem(`selectedTime.${this.$route.params.keyid}`)
        );
        let oldLength = JSON.parse(
          localStorage.getItem(`timesLength.${this.$route.params.keyid}`)
        );

        let setDate = "";
        let setTime = "";

        if (oldDate && oldTime && oldLength && !isChange) {
          if (oldDate.text) {
            oldDate.day.word = this.$enum.weekType.getByCht(
              oldDate.day.text
            ).text;
          }
          this.selectedDate = oldDate;
          if (oldTime.text.length == 7 && oldTime.isOrderFull != true) {
            this.selectedTime = oldTime;
          } else {
            localStorage.setItem(
              `selectedTime.${this.$route.params.keyid}`,
              JSON.stringify(this.selectedTime)
            );
          }
          let currentDate = this.takeTime.dateTypes.find((d) => {
            return (
              d.value == this.selectedDate.value &&
              d.text == this.selectedDate.text
            );
          });
          let currentTime = this.takeTime.timeTypes.find((t) => {
            return (
              t.value == this.selectedTime.value &&
              t.text == this.selectedTime.text
            );
          });

          if (!currentDate || (!currentTime && this.selectedDate.value == 0)) {
            this.selectedDate = this.takeTime.dateTypes[0];
            this.selectedTime = this.takeTime.timeTypes.find((timeType) => {
              return timeType.text.length == 7;
            }); //選擇最接近的非滿單的時間選項
          }

          if (
            currentDate &&
            currentTime &&
            this.selectedDate.text == currentDate.text &&
            oldLength == this.takeTime.timeTypes.length
          ) {
            if (this.selectedDate.value == 0) {
              setDate = this.$t("menu.today");
            } else {
              setDate = `${this.selectedDate.text
                .substring(5, 7)
                .padStart(2, "0")}/${this.selectedDate.text
                .substring(8, 10)
                .padStart(2, "0")} (${this.selectedDate.day.word})`;
            }
            if (this.selectedTime.value == 0) {
              if (this.waitingSetting.WaitingType == 0) {
                setTime = `${this.store.WaitingNum}${this.$t(
                  "menu.minuteslater"
                )}`;
              } else if (this.waitingSetting.WaitingType == 1) {
                setTime = `${this.store.WaitingNum}${this.$t(
                  "menu.hourslater"
                )}`;
              }
            } else {
              setTime = `${this.selectedTime.text}`;
            }
            this.expectTakeTime = `${setDate} ${setTime}`;
            return;
          }
        }

        if (this.selectedDate.value == 0) {
          setDate = this.$t("menu.today");
        } else {
          setDate = `${this.selectedDate.text
            .substring(5, 7)
            .padStart(2, "0")}/${this.selectedDate.text
            .substring(8, 10)
            .padStart(2, "0")} (${this.selectedDate.day.word})`;
        }
        if (this.selectedTime.value == 0) {
          if (this.waitingSetting.WaitingType == 0) {
            setTime = `${this.store.WaitingNum}${this.$t("menu.minuteslater")}`;
          } else if (this.waitingSetting.WaitingType == 1) {
            setTime = `${this.store.WaitingNum}${this.$t("menu.hourslater")}`;
          }
        } else {
          setTime = `${this.selectedTime.text}`;
        }
        this.expectTakeTime = `${setDate} ${setTime}`;
        localStorage.setItem(
          `selectedDate.${this.$route.params.keyid}`,
          JSON.stringify(this.selectedDate)
        );
        localStorage.setItem(
          `selectedTime.${this.$route.params.keyid}`,
          JSON.stringify(this.selectedTime)
        );
        localStorage.setItem(
          `timesLength.${this.$route.params.keyid}`,
          JSON.stringify(this.takeTime.timeTypes.length)
        );
      }
    },
    setReserveDatas(isEdit) {
      this.takeTime = {
        dateTypes: [],
        timeTypes: [],
        currentNum: 0,
      }; //重設可選日期時間

      this.waitingSetting = {
        WaitingType: this.store.WaitingType,
        WaitingNum: this.store.WaitingNum,
        WaitingInterval: this.store.WaitingInterval,
        ReserveDays: this.store.ReserveDays,
        LastOrderMinutes: this.store.LastOrderMinutes,
      };
      if (this.store.WaitingInterval <= 0) {
        this.waitingSetting.WaitingInterval = 5; //如果時間區間設定<=0默認為5分鐘
      }

      let newDate = new Date();

      let firstDate = this.$func.getDate(); //取得今天日期

      if (this.store.ReserveDays == 0) {
        //若點餐僅開放當天
        this.takeTime.dateTypes.push({
          value: 0,
          day: {
            value: newDate.getDay(),
            text: `${this.weeks[newDate.getDay()].cht}`,
            word: `${this.weeks[newDate.getDay()].text}`,
          },
          text: `${firstDate}`,
          listText: this.$t("menu.today"),
        });
      } else if (this.store.ReserveDays == -1 || this.store.ReserveDays > 0) {
        //若點餐不限於當天
        let days = 30; //默認開放未來30天內可預訂
        if (this.store.ReserveDays > 0) {
          //若營業人有設定確切天數
          days = this.store.ReserveDays;
        }
        for (let i = 0; i < days + 1; i++) {
          let nowTime = newDate.getTime() + i * 86400000; //取得當下毫秒
          let nowDay = new Date(nowTime).getDay(); //取得星期
          if (!this.workDaysArr[nowDay].RestKeyword) {
            //若非店休日（mounted取得營業日程時遍歷店休日加入RestKeyword: "closedday"欄位）
            let dateType = {
              value: i,
              day: {
                value: nowDay,
                text: `${this.weeks[nowDay].cht}`,
                word: `${this.weeks[nowDay].text}`,
              },
              text: `${this.$func.getDate(nowTime)}`,
              listText: `${this.$func.getDate(nowTime)}`,
            };
            if (i == 0) {
              //如果是第一筆日期（當天）設定下拉列表顯示文字為今天
              dateType.listText = this.$t("menu.today");
            }
            this.takeTime.dateTypes.push(dateType); //儲存進可選日期下拉列表
          } else {
            if (i < this.waitingSetting.WaitingNum) {
              //計算開放時間內的店休日數
              this.restDates += 1;
            }
          }
        }
      }

      let workTimes = []; //存放營業時間段

      let nextOpenDay = {}; //最接近今天的營業日
      let isToday = false;
      for (let j = 0; j < 7; j++) {
        let day = (j + newDate.getDay()) % 7; //取得今天（日=0、一=1...六=6）
        if (!this.workDaysArr[day].RestKeyword) {
          //若非店休日
          nextOpenDay = this.workDaysArr[day];
          if (j == 0) {
            //且是今天
            isToday = true;
          }
          break;
        }
      }
      for (let index = 0; index < nextOpenDay.Times.length; index++) {
        //遍歷所有時間段
        let time = {
          //取得這個營業日的營業時間段
          startHr: parseInt(nextOpenDay.Times[index].substring(0, 2)),
          startMin: parseInt(nextOpenDay.Times[index].substring(3, 5)),
          endHr: parseInt(nextOpenDay.Times[index].substring(8, 10)),
          endMin: parseInt(nextOpenDay.Times[index].substring(11, 13)),
        };
        if (
          time.startHr * 60 + time.startMin ==
          time.endHr * 60 + time.endMin
        ) {
          //若店家設定的營業開始時間=結束時間，默認為整天
          time = {
            startHr: 0,
            startMin: 0,
            endHr: 24,
            endMin: 0,
          };
        }
        workTimes.push(time); //儲存到時間段陣列以進行計算
        this.intervalCalculate(workTimes[index], index, isToday);
        if (index == nextOpenDay.Times.length - 1) {
          //若已計算完所有時間區間
          if (this.hasIntervalCounts) {
            //若有開啟限單功能
            let currentDateIndex = this.takeTime.dateTypes.findIndex((date) => {
              return date.value == this.selectedDate.value;
            });
            if (currentDateIndex == -1) {
              this.selectedDate = this.takeTime.dateTypes[0];
              currentDateIndex = 0;
            }
            let dTO = {
              Text: this.takeTime.dateTypes[currentDateIndex].text,
              Times: [],
            }; //要取得的限單資料的dTO
            this.takeTime.timeTypes.forEach((timeType) => {
              dTO.Times.push(timeType.time.replace(/\s+/g, ""));
            });
            if (
              this.takeTime.timeTypes.length > 0 &&
              this.selectedDate.value == 0
            ) {
              //若選擇的日期為今天且還有營業時間可選
              this.$api.stores
                .getOrdersStatus(this.$route.params.keyid, dTO)
                .then((data) => {
                  //取得限單資料
                  let checkIsOrderFull = data;
                  if (!this.selectedDate.text)
                    //若未選擇任何時間（保險用）
                    this.selectedDate = this.takeTime.dateTypes[0];
                  this.takeTime.timeTypes.forEach((t, i) => {
                    //遍歷所有時間段中是否有回傳狀態為滿單的資料
                    let checkTime = t.time.replace(/\s+/g, "");
                    let isOrderFull = checkIsOrderFull.some((p) => {
                      return p.IsOrderFull && p.Time == checkTime;
                    });
                    if (isOrderFull) {
                      //若滿單則添加欄位isOrderFull
                      t.isOrderFull = true;
                      if (t.text.length == 7)
                        //檢查此時間段是否尚未加入滿單提醒文字
                        t.text += ` (${this.$t(
                          "menu.thistimeslotisfullybooked"
                        )})`;
                    }
                    if (i == this.takeTime.timeTypes.length - 1) {
                      //若遍歷已達最末項
                      this.selectedTime = this.takeTime.timeTypes.find(
                        (timeType) => {
                          return timeType.text.length == 7;
                        }
                      ); //選擇最接近的非滿單的時間選項
                      this.checkOverTime(
                        index,
                        isToday,
                        workTimes,
                        nextOpenDay,
                        newDate
                      );
                      this.setExpectTakeTime(!isEdit);
                    }
                  });
                });
            } else {
              //若選擇日期非今天
              this.checkOverTime(
                index,
                isToday,
                workTimes,
                nextOpenDay,
                newDate
              );
              this.setExpectTakeTime(!isEdit);
            }
          } else {
            let getTimeSlotIndex = this.getCurrentTimeSlotIndex(workTimes);
            if (getTimeSlotIndex > -1) {
              this.setFastestTime(
                getTimeSlotIndex,
                isToday,
                workTimes,
                newDate
              );
            }
            this.checkOverTime(index, isToday, workTimes, nextOpenDay, newDate);

            this.setExpectTakeTime(!isEdit);
          }
        }
      }
    },
    checkOverTime(index, isToday, workTimes, nextOpenDay, newDate) {
      let waitingNum = 0;
      if (this.store.WaitingType == 0)
        waitingNum = this.store.WaitingNum + this.store.LastOrderMinutes;
      if (this.store.WaitingType == 1)
        waitingNum = this.store.WaitingNum * 60 + this.store.LastOrderMinutes;
      let currentTime =
        newDate.getHours() * 60 + newDate.getMinutes() + waitingNum;
      let endTime = workTimes[index].endHr * 60 + workTimes[index].endMin;
      if (
        this.waitingSetting.ReserveDays == 0 &&
        ((isToday && currentTime > endTime) || !isToday)
      ) {
        this.expectTakeTime = this.$t("menu.closedtoday");
        localStorage.removeItem(`selectedDate.${this.$route.params.keyid}`);
        localStorage.removeItem(`selectedTime.${this.$route.params.keyid}`);
        localStorage.removeItem(`timesLength.${this.$route.params.keyid}`);
        this.hasEdit = false;
        return;
      } else if (
        this.store.WaitingType == 2 &&
        index == nextOpenDay.Times.length - 1
      ) {
        this.takeTime.dateTypes.splice(
          0,
          this.waitingSetting.WaitingNum - this.restDates
        );
        if (this.takeTime.dateTypes.length > 0) {
          // if (!isEdit) this.selectedDate = this.takeTime.dateTypes[0];
          this.selectedDate = this.takeTime.dateTypes[0];
          let selectedWorkDay = this.workDaysArr.find((d) => {
            return this.selectedDate.day.text == d.Text;
          });
          this.overOpenTime(selectedWorkDay.Times);
        } else {
          this.expectTakeTime = this.$t("menu.ordertimehaspassed");
          this.hasEdit = false;
          return;
        }
      } else {
        const next = nextOpenDay.Times[nextOpenDay.Times.length - 1];
        let lastTime = {
          startHr: parseInt(next.substring(0, 2)),
          startMin: parseInt(next.substring(3, 5)),
          endHr: parseInt(next.substring(8, 10)),
          endMin: parseInt(next.substring(11, 13)),
        };
        if (
          lastTime.startHr == lastTime.endHr &&
          lastTime.startMin == lastTime.endMin
        ) {
          lastTime = {
            startHr: 0,
            startMin: 0,
            endHr: 24,
            endMin: 0,
          };
        }
        let num = null;
        if (this.waitingSetting.WaitingType == 0) {
          num = this.waitingSetting.WaitingNum;
        } else if (this.waitingSetting.WaitingType == 1) {
          num = this.waitingSetting.WaitingNum * 60;
        }
        if (
          newDate.getHours() * 60 +
            newDate.getMinutes() +
            num +
            this.waitingSetting.LastOrderMinutes >
          lastTime.endHr * 60 + lastTime.endMin
        ) {
          this.takeTime.dateTypes.splice(0, 1);
          if (this.takeTime.dateTypes.length > 0) {
            // if (!isEdit) this.selectedDate = this.takeTime.dateTypes[0];
            this.selectedDate = this.takeTime.dateTypes[0];
            let selectedWorkDay = this.workDaysArr.find((d) => {
              return this.selectedDate.day.text == d.Text;
            });
            this.overOpenTime(selectedWorkDay.Times);
          } else {
            this.expectTakeTime = this.$t("menu.closedtoday");
            this.hasEdit = false;
            return;
          }
        }
      }
    },
    getCurrentTimeSlotIndex(timeSlots) {
      const now = new Date();
      let waitingNum = 0;
      if (this.store.WaitingType == 0)
        waitingNum = this.store.WaitingNum + this.store.LastOrderMinutes;
      if (this.store.WaitingType == 1)
        waitingNum = this.store.WaitingNum * 60 + this.store.LastOrderMinutes;
      const currentTime = now.getHours() * 60 + now.getMinutes() + waitingNum; // 轉換為分鐘表示的當前時間

      return timeSlots.findIndex((slot) => {
        const slotStart = slot.startHr * 60 + slot.startMin; // 轉換為分鐘表示的開始時間
        const slotEnd = slot.endHr * 60 + slot.endMin; // 轉換為分鐘表示的結束時間

        return currentTime >= slotStart && currentTime < slotEnd; // 判斷當前時間是否在這個時間段內
      });
    },
    setFastestTime(index, isToday, workTimes, newDate) {
      if (
        isToday &&
        this.waitingSetting.WaitingNum > 0 &&
        !this.takeTime.timeTypes.some((t) => t.value == 0)
      ) {
        if (this.store.WaitingType == 0) {
          let currentTime =
            newDate.getHours() * 60 +
            newDate.getMinutes() +
            this.store.WaitingNum;
          let startTime =
            workTimes[index].startHr * 60 + workTimes[index].startMin;
          let endTime = workTimes[index].endHr * 60 + workTimes[index].endMin;
          if (
            (currentTime >= startTime &&
              currentTime < endTime &&
              newDate.getHours() +
                Math.floor(
                  (newDate.getMinutes() +
                    this.waitingSetting.LastOrderMinutes) /
                    60
                ) <
                workTimes[index].endHr) ||
            (newDate.getHours() +
              Math.floor(
                (newDate.getMinutes() + this.waitingSetting.LastOrderMinutes) /
                  60
              ) ==
              workTimes[index].endHr &&
              (newDate.getMinutes() +
                60 +
                this.waitingSetting.LastOrderMinutes) %
                60 <=
                workTimes[index].endMin)
          ) {
            let fastestTime = this.$func.getTime(
              new Date().getTime() + this.waitingSetting.WaitingNum * 60000
            );
            if (!this.hasIntervalCounts) {
              this.takeTime.timeTypes.splice(0, 0, {
                value: 0,
                text: `${this.store.WaitingNum}${this.$t("menu.minuteslater")}`,
                time: fastestTime,
              });
            }
          }
        } else if (this.store.WaitingType == 1) {
          let currentTime =
            newDate.getHours() * 60 +
            newDate.getMinutes() +
            this.store.WaitingNum * 60;
          let startTime =
            workTimes[index].startHr * 60 + workTimes[index].startMin;
          let endTime = workTimes[index].endHr * 60 + workTimes[index].endMin;
          if (
            (currentTime >= startTime &&
              currentTime < endTime &&
              newDate.getHours() +
                Math.floor(
                  (newDate.getMinutes() +
                    this.waitingSetting.LastOrderMinutes) /
                    60
                ) <
                workTimes[index].endHr) ||
            (newDate.getHours() +
              Math.floor(
                (newDate.getMinutes() + this.waitingSetting.LastOrderMinutes) /
                  60
              ) ==
              workTimes[index].endHr &&
              (newDate.getMinutes() +
                60 +
                this.waitingSetting.LastOrderMinutes) %
                60 <=
                workTimes[index].endMin)
          ) {
            let fastestTime = this.$func.getTime(
              new Date().getTime() + this.waitingSetting.WaitingNum * 60 * 60000
            );
            if (!this.hasIntervalCounts) {
              this.takeTime.timeTypes.splice(0, 0, {
                value: 0,
                text: `${this.store.WaitingNum}${this.$t("menu.hourslater")}`,
                time: fastestTime,
              });
            }
          }
        }
      }
    },
    intervalCalculate(workTime, index, isToday) {
      //計算最接近的今天的營業時間區間（可能包含今天）
      let startInterval = 0;
      let baseTime = 0;

      if (this.waitingSetting.WaitingType == 0) {
        this.takeTime.currentNum =
          new Date().getMinutes() + this.waitingSetting.WaitingNum;
      } else if (this.waitingSetting.WaitingType == 1) {
        this.takeTime.currentNum =
          new Date().getMinutes() + this.waitingSetting.WaitingNum * 60;
      }

      let startIntervalCal = Math.floor(
        parseInt(
          (this.takeTime.currentNum / this.waitingSetting.WaitingInterval) * 10
        ) / 10
      );

      let selectedWorkDay = this.workDaysArr.find((d) => {
        return this.selectedDate.day.text == d.Text;
      });
      let midnightCheck =
        workTime.endHr == 24 &&
        this.workDaysArr[
          (this.workDaysArr.indexOf(selectedWorkDay) + 1) % 7
        ].Times[0].substring(0, 5) == "00:00";

      for (
        let i = 0;
        i < Math.floor(60 / this.waitingSetting.WaitingInterval) * 24;
        i++
      ) {
        if (isToday && workTime.startHr <= new Date().getHours()) {
          startInterval = startIntervalCal;
          baseTime = new Date().getHours();
        } else {
          startInterval = Math.floor(
            workTime.startMin / this.waitingSetting.WaitingInterval
          );
          baseTime = workTime.startHr;
        }

        if (isToday) {
          startInterval += 1;
        }

        let t = { value: 1, text: "", time: "" };
        if (index > 0) {
          t.value = this.takeTime.timeTypes.length + 1;
        } else {
          t.value = i + 1;
        }

        let hour =
          baseTime +
          Math.floor(
            (this.waitingSetting.WaitingInterval * (startInterval + i)) / 60
          );
        let minute = 0;
        if (
          ((startInterval + i) * this.waitingSetting.WaitingInterval) / 60 <
          1
        ) {
          minute = (startInterval + i) * this.waitingSetting.WaitingInterval;
        } else {
          minute =
            ((startInterval + i) * this.waitingSetting.WaitingInterval) % 60;
        }

        if (
          hour >= 24 ||
          hour > workTime.endHr ||
          (workTime.endMin - this.waitingSetting.LastOrderMinutes < 0 &&
            hour + 1 == workTime.endHr &&
            minute >
              (workTime.endMin - this.waitingSetting.LastOrderMinutes + 60) %
                60 &&
            !midnightCheck) ||
          (hour == workTime.endHr &&
            minute > workTime.endMin - this.waitingSetting.LastOrderMinutes &&
            !midnightCheck)
        ) {
          return i;
        } else {
          t.text = `${hour.toString().padStart(2, "0")} : ${minute
            .toString()
            .padStart(2, "0")}`;
          t.time = `${hour.toString().padStart(2, "0")} : ${minute
            .toString()
            .padStart(2, "0")} : 00`;
          this.takeTime.timeTypes.push(t); //儲存進可選時間下拉列表
        }
      }
    },
    overOpenTime(times) {
      this.workTimes = [];
      this.takeTime.timeTypes = [];
      for (let index = 0; index < times.length; index++) {
        let time = {
          startHr: parseInt(times[index].substring(0, 2)),
          startMin: parseInt(times[index].substring(3, 5)),
          endHr: parseInt(times[index].substring(8, 10)),
          endMin: parseInt(times[index].substring(11, 13)),
        };
        if (time.startHr == time.endHr && time.startMin == time.endMin) {
          time = {
            startHr: 0,
            startMin: 0,
            endHr: 24,
            endMin: 0,
          };
        }
        this.workTimes.push(time);
        this.tomorrowIntervalCalculate(this.workTimes[index], index);
      }
    },
    tomorrowIntervalCalculate(workTime, index) {
      let selectedWorkDay = this.workDaysArr.find((d) => {
        return this.selectedDate.day.text == d.Text;
      });
      let midnightCheck =
        workTime.endHr == 24 &&
        this.workDaysArr[
          (this.workDaysArr.indexOf(selectedWorkDay) + 1) % 7
        ].Times[0].substring(0, 5) == "00:00";

      for (
        let i = 0;
        i < Math.floor(60 / this.waitingSetting.WaitingInterval) * 24;
        i++
      ) {
        let t = { value: 1, text: "", time: "" };
        if (index > 0) {
          t.value = this.takeTime.timeTypes.length + 1;
        } else {
          t.value = i + 1;
        }
        let hour =
          workTime.startHr +
          Math.floor(
            (this.waitingSetting.WaitingInterval * i + workTime.startMin) / 60
          );
        let minute = 0;
        if (
          (i + 1) * this.waitingSetting.WaitingInterval +
            workTime.startMin / 60 <
          1
        ) {
          minute = i * this.waitingSetting.WaitingInterval + workTime.startMin;
        } else {
          minute =
            (i * this.waitingSetting.WaitingInterval + workTime.startMin) % 60;
        }

        if (
          hour >= 24 ||
          hour > workTime.endHr ||
          (workTime.endMin - this.waitingSetting.LastOrderMinutes < 0 &&
            hour + 1 == workTime.endHr &&
            minute >
              (workTime.endMin - this.waitingSetting.LastOrderMinutes + 60) %
                60 &&
            !midnightCheck) ||
          (hour == workTime.endHr &&
            minute > workTime.endMin - this.waitingSetting.LastOrderMinutes &&
            !midnightCheck) ||
          i == Math.floor(60 / this.waitingSetting.WaitingInterval) * 24 - 1
        ) {
          this.selectedTime = this.takeTime.timeTypes[0];
          this.setExpectTakeTime();
          return;
        } else {
          t.text = `${hour.toString().padStart(2, "0")} : ${minute
            .toString()
            .padStart(2, "0")}`;
          t.time = `${hour.toString().padStart(2, "0")} : ${minute
            .toString()
            .padStart(2, "0")} : 00`;
          this.takeTime.timeTypes.push(t);
        }
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  mounted() {
    // $(".order-display").css("overflow", "hidden");
    $("body").css("overflow", "auto");

    const token = this.$cookie.member.getToken();
    const vm = this;

    this.isGrid = localStorage.getItem("isGrid") == "true";

    this.$store.dispatch("saveInStore", false);
    this.$cookie.table.remove();
    localStorage.removeItem("salId");
    localStorage.removeItem("salTempId");
    localStorage.removeItem("tableMapId");
    localStorage.removeItem("tableNum");
    localStorage.removeItem("tableStartTime");

    this.store = this.$cookie.store.get(this.$route.params.keyid);

    let defaultLang = "zh";
    let localCurrentLang = localStorage.getItem("currentLang");
    let needResetLang =
      (!this.store.HasEnglish && localCurrentLang == "en") ||
      (!this.store.HasJapanese && localCurrentLang == "jp") ||
      (!this.store.HasKorean && localCurrentLang == "kr") ||
      (!this.store.HasFrench && localCurrentLang == "fr");
    if (localCurrentLang && !needResetLang) {
      defaultLang = localCurrentLang;
    }
    this.$i18n.locale = defaultLang;
    localStorage.setItem("currentLang", defaultLang);
    this.$store.dispatch("setLanguage", defaultLang);
    this.currentLang = this.$enum.langType.getByLang(defaultLang);

    if (this.store.WorkDays) {
      this.workDaysArr = Object.values(this.store.WorkDays);
      this.workDaysArr.forEach((workDay) => {
        const allDayIndex = workDay.Times.findIndex((time) => {
          let t = {
            startHr: parseInt(time.substring(0, 2)),
            startMin: parseInt(time.substring(3, 5)),
            endHr: parseInt(time.substring(8, 10)),
            endMin: parseInt(time.substring(11, 13)),
          };
          return t.startHr == t.endHr && t.startMin == t.endMin;
        });
        if (allDayIndex > -1) {
          let item = "00:00 - 24:00";
          workDay.Times.splice(0, workDay.Times.length);
          workDay.Times.push(item);
        }
        if (workDay.Times[0] == "公休") workDay.RestKeyword = "closedday";
        workDay.Name = this.$enum.weekType.getByCht(workDay.Text).text;
      });
    } else {
      this.hasEdit = false;
    }
    this.cart = this.$cookie.cart.get(this.$route.params.keyid);
    this.$api.menus
      .get({
        eat_type: 0,
        language_type: this.currentLang.value,
      })
      .then((data) => {
        vm.foodKindGroups = data;
        vm.setFoodsQty();
      });
    if (liff.isInClient() && this.store.LineLiffId) {
      liff
        .init({ liffId: `${vm.store.LineLiffId}` })
        .then(() => {
          liff.getProfile().then((profile) => {
            // console.log(profile);
            vm.$store.dispatch("setLineUserId", profile.userId);
            if (!token && vm.$store.state.isFirstTime) {
              vm.$api.members
                .searchLineUserId(vm.store.DeviceId, vm.$store.state.lineUserId)
                .then((data) => {
                  vm.$store.dispatch("updateTime", false);
                  vm.$store.dispatch("updateLoading", false);
                  vm.$cookie.member.set(data);
                  vm.$cookie.customer.set(data);
                  vm.nowToken = vm.$cookie.member.getToken();
                });
            } else {
              vm.$store.dispatch("updateTime", false);
              vm.$store.dispatch("updateLoading", false);
            }
          });
        })
        .catch(() => {
          this.$swal("LINE連結未正確設定，請聯繫店家 !");
        });
    }
    if (!liff.isInClient()) this.$store.dispatch("updateLoading", false);

    // let now = new Date().getDay();
    // console.log(now);
    // if (this.store.ReserveDays == 0 && this.workDaysArr[now].RestKeyword) {
    //   this.expectTakeTime = this.$t("menu.closedtoday");
    //   return;
    // }
    // console.log("test");

    //如果有出現bug先把這段註解掉
    const today = this.$func.getDate();
    const localSelectedDate = localStorage.getItem(
      `lastDate.${this.$route.params.keyid}`
    );
    if (!localSelectedDate || today !== localSelectedDate) {
      localStorage.removeItem(`selectedDate.${this.$route.params.keyid}`);
      localStorage.removeItem(`selectedTime.${this.$route.params.keyid}`);
      localStorage.removeItem(`timesLength.${this.$route.params.keyid}`);
      localStorage.removeItem(`cart.${this.$route.params.keyid}`);
      localStorage.setItem(`lastDate.${this.$route.params.keyid}`, today);
      this.cart = this.$cookie.cart.getDefault();
    }
    //如果有出現bug先把這段註解掉

    if (this.hasEdit) {
      let storageDate = null;
      if (localStorage.getItem(`selectedDate.${this.$route.params.keyid}`)) {
        storageDate = JSON.parse(
          localStorage.getItem(`selectedDate.${this.$route.params.keyid}`)
        );
        if (storageDate.text) {
          storageDate.day.word = this.$enum.weekType.getByCht(
            storageDate.day.text
          ).text;
        }
      }
      let storageTime = null;
      if (localStorage.getItem(`selectedTime.${this.$route.params.keyid}`)) {
        storageTime = JSON.parse(
          localStorage.getItem(`selectedTime.${this.$route.params.keyid}`)
        );
      }
      if (storageDate && storageTime) {
        this.selectedDate = storageDate;
        this.selectedTime = storageTime;

        let setDate = "";
        let setTime = "";

        if (this.selectedDate.value == 0) {
          setDate = this.$t("menu.today");
        } else {
          setDate = `${this.selectedDate.text
            .substring(5, 7)
            .padStart(2, "0")}/${this.selectedDate.text
            .substring(8, 10)
            .padStart(2, "0")} (${this.selectedDate.day.word})`;
        }
        if (this.selectedTime.value == 0) {
          if (this.waitingSetting.WaitingType == 0) {
            setTime = `${this.store.WaitingNum}${this.$t("menu.minuteslater")}`;
          } else if (this.waitingSetting.WaitingType == 1) {
            setTime = `${this.store.WaitingNum}${this.$t("menu.hourslater")}`;
          }
        } else {
          setTime = `${this.selectedTime.text}`;
        }
        this.expectTakeTime = `${setDate} ${setTime}`;
      }
    }
    if (this.workDaysArr.length > 0) {
      this.setReserveDatas(true);
    }
  },
  beforeRouteLeave(to, from, next) {
    localStorage.removeItem("orderFrom");
    next();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>
