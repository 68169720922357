const zh = {
  menu: {
    openinghours: "營業時間",
    login: "登入",
    joinstore: "加入會員",
    open: "營業中",
    closed: "已打烊",
    preorder: "預訂",
    left: "剩餘 ",
    soldout: "已售完",
    cart: "購物車",
    cartitem: " 件",
    pleasechooseyourmeal: "請選擇餐點",
    closedday: "公休",
    today: "今天",
    stocklimitreached: "已達庫存上限",
    closedtoday: "本日已打烊",
    ordertimehaspassed: "已超過最後點餐時間",
    thistimeslotisfullybooked: "此時段已滿單",
    minuteslater: "分鐘後",
    hourslater: "小時後",
    orderedmeals: "已點餐點",
    addmoremeals: "加點餐點",
    takeout: "外帶",
    checkmeals: "確認餐點",
    orderedcounts: "已選品項",
  },
  week: {
    sunday: "星期日",
    monday: "星期一",
    tuesday: "星期二",
    wednesday: "星期三",
    thursday: "星期四",
    friday: "星期五",
    saturday: "星期六",
  },
  takeAwayTime: {
    expectedpickuptime: "預計取餐時間",
    pickupdate: "取餐日期",
    pickuptime: "取餐時間",
    otherdates: "其他日期",
    confirm: "確定",
    today: "今天",
    orderfull: "滿單",
    isorderfull: " (此時段已滿單)",
  },
  checkout: {
    cart: "購物車",
    expectedpickuptime: "預計取餐時間",
    cancel: "刪單",
    notice: "說明",
    orderdetails: "訂單明細",
    total: "總計",
    memo: "備註",
    member: "會員",
    name: "姓名",
    phonenumber: "手機",
    receipt: "發票",
    uniformnumber: "統一編號",
    vehiclenumber: "手機載具",
    submitorder: "送出訂單",
    today: "今天",
    closedtoday: "本日已打烊",
    thistimeslotisfullybooked: "此時段已滿單",
    ordertimehaspassed: "已超過最後點餐時間",
    allergiesoringredients: "如果有任何過敏或不想添加的食材，請告訴我們。",
    vehiclenumberisincorrect: "載具格式有誤",
    areyousuretosubmittheorder: "確定送出訂單嗎 ?",
    businesshourshavechanged: "營業時段已變更，請重新確認時間",
    areyousureyouwanttodeletethis: "確定刪除嗎 ?",
    areyousureyouwanttodeletetheentireorder: "確定刪除整張訂單嗎 ?",
    thedatehasbeenupdated: "日期已更新 !",
    minuteslater: "分鐘後",
    hourslater: "小時後",
    continueordering: "繼續點餐",
    paymentmethod: "付款方式",
    payatstore: "到店付款",
    paymentconfirming: "確認付款中",
    paymentcompleted: "付款完成",
    paymentfailed: "付款失敗",
  },
  login: {
    login: "登入",
    phonenumber: "手機號碼",
    password: "密碼",
    forgotpassword: "忘記密碼",
    register: "註冊",
  },
  join: {
    join: "加入",
    joinstore: "加入會員",
    alreadyjoin: "您已登入帳號，可直接加入。",
  },
  forgotPassword: {
    forgotpassword: "忘記密碼",
    phonenumber: "手機號碼",
    getverifycode: "取得驗證碼",
    retry: "s 重試",
    verifycode: "驗證碼",
    newpassword: "新密碼",
    enterpasswordagain: "再次輸入密碼",
    pleaseentersamepassword: "請輸入相同的密碼",
    save: "儲存",
    pleasetryagainlater: "請稍後再試",
    pleaseenterphonenumber: "請輸入手機號碼",
    pleasecheckyourmobilemessage: "請檢查手機簡訊",
  },
  member: {
    profile: "基本資料",
    historyorder: "歷史訂單",
    changepassword: "修改密碼",
    reportissue: "問題回報",
    logout: "登出",
    name: "姓名",
    birthday: "生日",
    email: "Email",
    address: "住址",
    link: "綁定",
    unlink: "取消綁定",
    notlink: "未綁定",
    save: "儲存",
    changesuccess: "變更成功",
    areyousureyouwanttounlink: "確定解除綁定嗎 ?",
    unlinkseccessful: "解除綁定成功",
  },
  historyOrder: {
    historyorder: "歷史訂單",
    ordernumber: "單號",
    createtime: "建立時間",
    eattype: "用餐別",
    reservetime: "取餐時間",
    checkouttime: "結帳時間",
    memo: "備註",
    total: "總金額",
    confirming: "確認中",
    accepted: "已接受",
    rejected: "已拒絕",
    completed: "已完成",
    pickself: "自取",
    instore: "內用",
    takeout: "外帶",
    delivery: "外送",
  },
  order: {
    required: "必選",
    available: "可選",
    exceedsselectionlimit: "超過可選數量",
    minqtyreached: "已達最小數量",
    ok: "確定",
    cancel: "取消",
    mealsetsnotselected: "尚有套餐子商品未選擇",
    flavorsnotselected: "尚有口味未選擇",
    mealsetsover: "套餐超過可選數量",
    suretoreset: "將重設此品項，確定繼續嗎 ?",
    modifyflavors: "編輯口味",
    save: "儲存",
    soldout: "已售完",
    memo: "備註",
  },
  trade: {
    orderinformation: "訂單資訊",
    orderdetail: "訂單明細",
    ordernumber: "單號",
    confirming: "確認中",
    accepted: "已接受",
    rejected: "已拒絕",
    completed: "已完成",
    tablenumber: "內用桌號",
    tablenum: "桌號",
    name: "姓名",
    memo: "備註",
    total: "總金額",
    receiptinformation: "發票資訊",
    uniformnumber: "統一編號",
    vehiclenumber: "手機載具",
    backtomenu: "回到點餐頁面",
    rejectreason: "拒絕原因",
    completetime: "完成時間",
    createtime: "建立時間",
    reservetime: "取餐時間",
    member: "會員",
    changelogs: "變更紀錄",
    scanqrcodetocheck: "請至櫃檯掃碼結帳",
  },
  changePassword: {
    changepassword: "修改密碼",
    oldpassword: "舊密碼",
    newpassword: "新密碼",
    enterpasswordagain: "再次輸入密碼",
    pleaseentersamepassword: "請輸入相同的密碼",
    changesuccess: "變更成功",
    save: "儲存",
  },
  register: {
    memberregister: "會員註冊",
    name: "姓名",
    phonenumber: "手機號碼",
    verifycode: "驗證碼",
    password: "密碼",
    enterpasswordagain: "確認密碼",
    pleaseentersamepassword: "請輸入相同的密碼",
    getverifycode: "取得驗證碼",
    retry: "s 重試",
    register: "註冊",
    alreadyhaveanaccount: "已有帳號",
    pleasetryagainlater: "請稍後再試",
    pleaseenterphonenumber: "請輸入手機號碼",
    pleasecheckyourmobilemessage: "請檢查手機簡訊",
  },
  feedback: {
    reportissue: "回報問題",
    pleaseentertheissueyouwanttoreport: "請輸入您想回報的問題",
    submit: "送出",
    thanksforyourreport: "感謝您的回報",
  },
  error: {
    sorrymessage: "抱歉，您所訪問的網頁不存在或已刪除 !",
    goback: "回上一頁",
    urlexpired: "此點餐網址已失效",
  },
};

export default zh;
