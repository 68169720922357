export default {
  getDefault() {
    return {
      Counts: 0,
      Total: 0,
      Items: [],
    };
  },

  get(id) {
    let cartStr = window.localStorage[`cart.${id}`];
    if (!cartStr) {
      let cart = this.getDefault();
      this.set(id, cart);
      return cart;
    } else {
      let cart = JSON.parse(cartStr);
      this.calculate(id, cart);
      return cart;
    }
  },
  set(id, cart) {
    window.localStorage[`cart.${id}`] = JSON.stringify(cart);
  },
  calculate(id, cart) {
    cart.Counts = 0;
    cart.Total = 0;
    cart.Items.forEach((item) => {
      this.calculateCombo(item);
      cart.Counts = cart.Counts + item.Qty;
      cart.Total = cart.Total + item.Total;
      if (item.Combos) {
        item.Combos.forEach((combo) => {
          cart.Total = cart.Total + combo.Total;
        });
      }
    });
    cart.Total = Math.round(cart.Total);
    this.set(id, cart);
    return cart;
  },

  calculateCombo(item) {
    this.calculateItem(item);
    item.SumTotal = item.Total;
    if (item.Combos) {
      item.Combos.forEach((combo) => {
        combo.Qty = item.Qty;
        combo.OriginalPrice = combo.Price;
        this.calculateItem(combo);
        item.SumTotal = item.SumTotal + combo.Total;
      });
    }
  },

  addItem(id, item) {
    let cart = this.get(id);
    cart.Items.push(item);
    return this.calculate(id, cart);
  },
  removeItem(id, itemId) {
    let cart = this.get(id);
    let index = cart.Items.findIndex((det) => {
      return det.ItemId == itemId;
    });
    if (index < 0) return;
    cart.Items.splice(index, 1);
    return this.calculate(id, cart);
  },
  removeAllItem(id) {
    let cart = this.get(id);
    cart.Items.length = 0;
    return this.calculate(id, cart);
  },
  calculateItem(item) {
    item.TastePrice = 0;
    item.FoodTaste = null;

    if (item.Tastes) {
      let tasteNames = [];
      let tasteDisplayNames = [];
      item.Tastes.forEach((taste) => {
        item.TastePrice = item.TastePrice + taste.Price;
        tasteNames.push(taste.TasteName);
        tasteDisplayNames.push(taste.DisplayName);
      });
      item.FoodTaste = tasteNames.join();
      item.FoodTasteDisplayName = tasteDisplayNames.join();
    }
    item.SalPrice = item.OriginalPrice + item.TastePrice;
    item.Total = item.Qty * item.SalPrice;
  },

  addQty(id, itemId) {
    let cart = this.get(id);
    let item = cart.Items.find((det) => {
      return det.ItemId == itemId;
    });
    item.Qty = item.Qty + 1;
    return this.calculate(id, cart);
  },
  minusQty(id, itemId) {
    let cart = this.get(id);
    let item = cart.Items.find((det) => {
      return det.ItemId == itemId;
    });
    item.Qty = item.Qty - 1;
    if (item.Qty == 0) {
      return this.removeItem(id, itemId);
    }
    return this.calculate(id, cart);
  },
  checkStore(id, time) {
    let cart = this.get(id);
    if (time) {
      if (cart.Items.length > 0) {
        if (!cart.MenuTime && cart.MenuTime < time) {
          cart = this.getDefault();
          cart.MenuTime = time;
          this.calculate(id, cart);
          return false;
        }
      }
    }
    return true;
  },
};
