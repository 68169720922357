<template>
  <div class="invalid-container">
    <img class="invalid-img" src="../imgs/invalid.png" alt="invalid" />
    <div class="invalid-text">{{ $t("checkout.paymentconfirming") }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      salId: "",
      transactionId: "",
    };
  },
  mounted() {
    const store = JSON.parse(
      localStorage.getItem(`store.${this.$route.params.keyid}`)
    );
    this.$store.dispatch("updateLoading", false);
    this.salId = localStorage.getItem("lineSalId");
    this.transactionId = localStorage.getItem("lineTransactionId");
    localStorage.removeItem("lineSalId");
    localStorage.removeItem("lineTransactionId");
    this.$api.checkouts
      .paymentConfirming(store.DeviceId, this.salId, {
        TransactionId: this.transactionId,
      })
      .then(() => {
        this.$cookie.cart.set(
          this.$route.params.keyid,
          this.$cookie.cart.getDefault()
        );
        this.$router.push({
          name: "Trade",
          params: {
            id: this.salId,
          },
        });
      })
      .catch(() => {
        this.$router.push({
          name: "PaymentCancel",
        });
      });
  },
};
</script>

<style lang="scss">
.invalid-container {
  width: 100%;
  height: calc(100vh - 99px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .invalid-img {
    width: 180px;
    padding: 20px;
  }
  .invalid-text {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 50px;
  }
}
</style>
