<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
      store: {
        code: "",
        clientId: "1657562870",
        redirectUri:
          "https://ushow-order-kouyu-japaneast.azurewebsites.net/2997779d1e06/callback",
        clientSecret: "8af4cef5af8c2e55868ee6e4ba0291c4",
      },
    };
  },
  mounted() {
    const code = new URL(window.location.href).searchParams.get("code");
    this.store.code = code;

    this.$api.devices
      .getLineInfo(this.store)
      .then((response) => {
        this.$store.dispatch("saveLineResponse", response);
        // this.$router.push({
        //   name: "CheckPhoneVerify",
        // });
      })
      .catch((error) => {
        this.$swal.fire({
          icon: "error",
          title: error.data.Message,
          showConfirmButton: true,
        });
      });
  },
};
</script>
