import i18n from "@/langs/i18n";
export function getAll() {
  return [
    {
      value: 0,
      name: "instore",
      text: `${i18n.t("historyOrder.instore")}`,
    },
    {
      value: 1,
      name: "takeout",
      text: `${i18n.t("historyOrder.takeout")}`,
    },
    {
      value: 2,
      name: "delivery",
      text: `${i18n.t("historyOrder.delivery")}`,
    },
    {
      value: 3,
      name: "pickself",
      text: `${i18n.t("historyOrder.pickself")}`,
    },
  ];
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
