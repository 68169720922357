<template>
  <div class="order order-display-checkout" :class="[trigger ? 'upOrder' : '']">
    <div class="order-display-container">
      <div class="order-display-content">
        <a class="btn-icon" @click="closed">
          <i class="fas fa-chevron-left"></i>
        </a>
        <div v-if="item.Food.ImageFileName" class="order-food-img-container">
          <img
            class="order-food-img"
            :src="item.Food.ImageFileName"
            alt="商品"
          />
        </div>
        <div v-else class="image-empty"></div>
        <div class="content-main order-content">
          <div class="order-content-header">
            <div class="order-food-content">
              <p class="order-food-name">{{ item.Food.DisplayName }}</p>
              <p class="order-food-memo">{{ item.Food.Introduction }}</p>
            </div>
            <p class="order-food-price">${{ item.Food.SalPrice }}</p>
          </div>

          <div
            class="order-group"
            v-for="foodComboGroup in item.FoodCombos"
            :key="foodComboGroup.ComboKind.ComboKindName"
          >
            <div class="order-group-header">
              <div class="flex space-between order-group-kind-name">
                <div class="order-group-title">
                  {{ foodComboGroup.ComboKind.DisplayName }}
                </div>
                <div
                  class="order-group-min"
                  v-if="foodComboGroup.FoodCombo.MinQty > 0"
                >
                  <p>必填{{ foodComboGroup.FoodCombo.MinQty }}</p>
                </div>
              </div>
              <p
                class="order-group-max"
                v-if="foodComboGroup.FoodCombo.MaxQty > 0"
              >
                可選{{ foodComboGroup.FoodCombo.MaxQty }}
              </p>
            </div>

            <div class="order-group-content">
              <div
                class="order-item"
                v-for="comboGroup in foodComboGroup.Combos"
                :key="comboGroup.Combo.ComboId"
                :data-id="comboGroup.Combo.ComboId"
              >
                <div
                  class="flex space-between items-center combo-item"
                  :data-combo="comboGroup.Combo.ComboId"
                  :data-combokind="comboGroup.Combo.ComboKindId"
                  :data-maxqty="foodComboGroup.FoodCombo.MaxQty"
                >
                  <div class="order-item-text flex items-center">
                    <input
                      type="checkbox"
                      :class="
                        comboGroup.Combo.Stock <= 0 &&
                        comboGroup.Combo.Stock != null
                          ? 'soldout'
                          : ''
                      "
                      :id="comboGroup.Combo.ComboId"
                      @click="comboClick(comboGroup, $event)"
                      :value="comboGroup.Combo.ComboId"
                      v-model="comboIds"
                      :disabled="
                        comboGroup.Combo.Stock <= 0 &&
                        comboGroup.Combo.Stock != null
                      "
                    />
                    <label
                      :for="comboGroup.Combo.ComboId"
                      :class="
                        comboGroup.Combo.Stock <= 0 &&
                        comboGroup.Combo.Stock != null
                          ? 'soldout'
                          : ''
                      "
                    >
                      {{ comboGroup.Combo.DisplayName }}
                      <p
                        v-if="
                          comboGroup.Combo.Stock <= 0 &&
                          comboGroup.Combo.Stock != null
                        "
                      >
                        {{ "(" + $t("order.soldout") + ")" }}
                      </p>
                    </label>
                  </div>
                  <p v-if="comboGroup.Combo.Price != 0">
                    + ${{ comboGroup.Combo.Price }}
                  </p>
                </div>

                <!-- <div
              class=""
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-left: 27px;
                padding: 10px 0;
              "
            >
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <div>1.</div>
                <div>測試</div>
              </div>
              <div
                class="order-combo-taste"
                v-if="getComboFoodTaste(comboGroup.Combo.ComboId) != undefined"
              >
                {{ getComboFoodTaste(comboGroup.Combo.ComboId) }}
              </div>
            </div> -->

                <order-combo-taste
                  v-if="comboGroup.IsShow"
                  :comboGroup="comboGroup"
                  :selectedCombo="selectedCombo"
                  class="combo-taste-checkout"
                  @close="comboClose"
                  @save="comboAdd"
                  @edit="comboEdit"
                ></order-combo-taste>
              </div>
            </div>
          </div>

          <order-food-taste
            :isEdit="isEdit"
            :foodTasteGroups="item.FoodTastes"
            :tastes="cartItem.Tastes"
            :trigger="trigger"
            @tasteChange="tasteChange"
          ></order-food-taste>

          <!-- <hr v-if="hasDetailMemo" /> -->
          <div v-if="hasDetailMemo" class="order-group">
            <div class="order-group-title">
              {{ $t("checkout.memo") }}
            </div>
            <div class="order-group-max">
              {{ $t("checkout.allergiesoringredients") }}
            </div>
            <textarea
              v-if="hasDetailMemo && !detailMemoTitle"
              rows="3"
              :placeholder="$t('checkout.allergiesoringredients')"
              class="order-memo-input"
              v-model="cartItem.Memo"
            ></textarea>
            <textarea
              v-else-if="hasDetailMemo && detailMemoTitle"
              rows="3"
              :placeholder="detailMemoTitle"
              class="order-memo-input"
              v-model="cartItem.Memo"
            ></textarea>
          </div>
        </div>
        <div class="order-footer-container" :class="[trigger ? 'upOrder' : '']">
          <div class="order-footer" style="margin-top: 0">
            <div class="order-qty">
              <button class="btn center order-qty-minus" @click="qtyMinus">
                <i class="fas fa-minus"></i>
              </button>
              <p class="order-qty-text">{{ cartItem.Qty }}</p>
              <button class="btn center" @click="qtyAdd">
                <i class="fas fa-plus"></i>
              </button>
            </div>
            <button class="order-check btn-border" @click="saved">
              $ {{ currentSumTotal }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderComboTaste from "./OrderComboTaste.vue";
import orderFoodTaste from "./OrderFoodTaste.vue";

export default {
  data() {
    return {
      item: { Food: {}, FoodCombos: [], FoodTastes: [] },
      cartItem: {
        Qty: 1,
        SalPrice: 0,
        Total: 0,
        Tastes: [],
        Combos: [],
        Memo: "",
      },
      comboIds: [],
      isEdit: true,
      currentSumTotal: 0,
      cart: {},
      isUpdating: false,
    };
  },
  props: ["store", "currentFood", "editingItemId", "trigger"],
  components: {
    orderFoodTaste,
    OrderComboTaste,
  },
  computed: {
    hasDetailMemo() {
      return this.store.HasDetailMemo;
    },
    detailMemoTitle() {
      return this.store.DetailMemoTitle;
    },
  },
  watch: {
    trigger(value) {
      if (value) {
        this.item = { Food: {}, FoodCombos: [], FoodTastes: [] };
        const viewModal = document.querySelector(".order-display-content");
        viewModal.scrollTop = 0;
        this.currentFood.FoodCombos.forEach((c) => {
          c.Combos.forEach((i) => {
            i.IsShow = false;
          });
        });
        this.item = this.$func.deepClone(this.currentFood);
        this.cart = JSON.parse(
          localStorage.getItem(`cart.${this.$route.params.keyid}`)
        );
        this.cartItem = this.cart.Items.find((i) => {
          return i.ItemId == this.editingItemId;
        });
        this.calculate();

        let defaultComboIds = [];

        this.cartItem.Combos.forEach((combo) => {
          let findId = defaultComboIds.find((id) => {
            return id == combo.ComboId;
          });
          if (!findId) {
            defaultComboIds.push(combo.ComboId);
          }
        });
        this.cartItem.Combos.forEach((currentCombo) => {
          this.item.FoodCombos.forEach((foodCombo) => {
            let comboIndex = foodCombo.Combos.findIndex((combo) => {
              return combo.Combo.ComboId == currentCombo.ComboId;
            });
            if (comboIndex > -1) {
              let defaultCombo = { ...currentCombo };
              this.$nextTick(() => this.setSelectedCombo(defaultCombo));
            }
          });
        });
        this.comboIds = defaultComboIds;
      } else {
        this.item.FoodCombos.forEach((c) => {
          c.Combos.forEach((i) => {
            i.IsShow = false;
          });
        });
        let combos = document.querySelectorAll(".selected-combo");
        let counters = document.querySelectorAll(".combo-counter");
        let combosArray = Array.from(combos);
        let countersArray = Array.from(counters);
        combosArray.forEach((combo) => combo.remove());
        countersArray.forEach((counter) => counter.remove());
        this.cartItem.Qty = 1;
        this.cartItem.SalPrice = 0;
        this.cartItem.Total = 0;
        this.cartItem.Combos = [];
        this.cartItem.Tastes = [];
        this.cartItem.Memo = "";
        this.comboIds = [];
      }
    },
    // "cartItem.Combos": {
    //   handler: function () {
    //     this.calculate();
    //   },
    //   deep: true,
    // },
    // "cartItem.Tastes"() {
    //   this.calculate();
    // },
    // comboIds(newValue) {
    //   let combos = [];
    //   newValue.forEach((id) => {
    //     let combo = this.cartItem.Combos.find((item) => item.ComboId == id);
    //     if (combo) {
    //       combos.push(combo);
    //     }
    //   });
    //   this.cartItem.Combos = combos;
    // },
  },
  methods: {
    setSelectedCombo(defaultCombo) {
      const comboItem = document.querySelector(
        `.combo-item[data-combo="${defaultCombo.ComboId}"]`
      );
      const comboContainer = document.querySelector(
        `[data-id="${defaultCombo.ComboId}"]`
      );

      if (
        comboItem.dataset.combo == defaultCombo.ComboId &&
        comboItem.dataset.combokind == defaultCombo.ComboKindId
      ) {
        if (
          defaultCombo.Count == 1 &&
          (comboItem.dataset.maxqty > 1 || comboItem.dataset.maxqty == 0)
        ) {
          let counter = document.createElement("div");
          counter.classList.add("combo-counter");
          if (comboItem.dataset.maxqty > 1) {
            counter.classList.add("bd-btm");
          }
          counter.dataset.combo = defaultCombo.ComboId;
          counter.dataset.combokind = defaultCombo.ComboKindId;
          let minusBtn = "";
          if (defaultCombo.FoodTaste == null) {
            minusBtn = `<div class="combo-counter-btn count-minus"><i class="fas fa-minus"></i></div>`;
          } else {
            minusBtn = `<div class="combo-counter-empty"></div>`;
          }
          let counterInner =
            minusBtn +
            `<div class="combo-counter-qty">${defaultCombo.Count}</div><div class="combo-counter-btn count-plus"><i class="fas fa-plus"></i></div>`;
          counter.innerHTML = counterInner;
          comboContainer.appendChild(counter);
          let counters = document.querySelectorAll(".combo-counter-btn");
          for (let i = 0; i < counters.length; i++) {
            counters[i].addEventListener("click", this.counterListener);
          }
          this.$nextTick(() => this.changeQtyBtn());
        }
        if (defaultCombo.FoodTaste == null) {
          return;
        } else {
          let selected = document.createElement("div");
          selected.classList.add("selected-combo");
          selected.classList.add("bd-btm");
          selected.dataset.combo = defaultCombo.ComboId;
          selected.dataset.count = defaultCombo.Count;
          let taste = "";
          if (defaultCombo.FoodTaste) {
            taste = `<div class="order-combo-taste-text">${defaultCombo.FoodTasteDisplayName}</div>`;
          } else if (
            defaultCombo.Tastes &&
            defaultCombo.Tastes.length == 0 &&
            defaultCombo.FoodTaste == ""
          ) {
            taste = `<div class="order-combo-taste-text">${this.$t(
              "order.modifyflavors"
            )}</div>`;
          }
          selected.innerHTML =
            `<div style="display: flex; justify-content: center; align-items: center; width: 100%">
              <div class="order-combo-taste-content">
                <div class="remove-selected">
                  <div class="remove-selected-item" data-combo="${defaultCombo.ComboId}" data-combokind="${defaultCombo.ComboKindId}" data-count="${defaultCombo.Count}"><i class="fas fa-times"></i>
                </div>
              </div>
              <div class="order-combo-taste-edit" data-combo="${defaultCombo.ComboId}" data-combokind="${defaultCombo.ComboKindId}" data-count="${defaultCombo.Count}">` +
            taste +
            `<i class="fas fa-chevron-right"></i>` +
            `</div></div></div>`;
          comboContainer.appendChild(selected);
          let removeBtn = selected.querySelector(".remove-selected-item");
          removeBtn.addEventListener("click", this.removeSelectedItem);

          let editBtn = selected.querySelector(".order-combo-taste-edit");
          let currentComboKind = this.item.FoodCombos.find((fc) => {
            return fc.ComboKind.ComboKindId == comboItem.dataset.combokind;
          });
          let currentCombo = currentComboKind.Combos.find((c) => {
            return c.Combo.ComboId == comboItem.dataset.combo;
          });
          if (currentCombo.FoodTastes.length > 0) {
            editBtn.addEventListener("click", this.editSelectedItem);
          }
        }
      }
    },
    changeQtyBtn(tgId) {
      let allCounters = document.querySelectorAll(".combo-counter");
      let qty = [];
      for (let i = 0; i < allCounters.length; i++) {
        let maxQty = parseInt(
          document.querySelector(
            `.combo-item[data-combokind="${allCounters[i].dataset.combokind}"]`
          ).dataset.maxqty
        );
        let comboQty = this.cartItem.Combos.filter((combo) => {
          return combo.ComboKindId == allCounters[i].dataset.combokind;
        });
        let allCurrentKindCounter = document.querySelectorAll(
          `.combo-counter[data-combokind="${allCounters[i].dataset.combokind}"]`
        );
        if (maxQty == comboQty.length) {
          for (let j = 0; j < allCurrentKindCounter.length; j++) {
            allCurrentKindCounter[j]
              .querySelector(".count-plus")
              .classList.add("btn-disable");
          }
        } else if (maxQty > comboQty.length) {
          for (let j = 0; j < allCurrentKindCounter.length; j++) {
            if (
              allCurrentKindCounter[j]
                .querySelector(".count-plus")
                .classList.contains("btn-disable")
            ) {
              allCurrentKindCounter[j]
                .querySelector(".count-plus")
                .classList.remove("btn-disable");
            }
          }
        }

        qty = this.cartItem.Combos.filter((combo) => {
          return allCounters[i].dataset.combo == combo.ComboId;
        });
        allCounters[i].querySelector(".combo-counter-qty").innerHTML =
          qty.length;
      }
      if (tgId) {
        let currentCombo = this.cartItem.Combos.filter((combo) => {
          return combo.ComboId == tgId;
        });
        return currentCombo.length;
      }
    },
    counterListener(e) {
      let tg = e.currentTarget;
      if (tg.classList.contains("btn-disable")) {
        return;
      }
      let tgParent = e.currentTarget.parentNode;
      let tgId = tgParent.dataset.combo;
      let isPlus = null;
      if (tg.classList.contains("count-plus")) {
        isPlus = true;
      } else {
        isPlus = false;
      }

      let currentCombo = null;
      this.item.FoodCombos.some((foodCombo) => {
        currentCombo = foodCombo.Combos.find((combo) => {
          return combo.Combo.ComboId == tgId;
        });
        return currentCombo != undefined;
      });
      if (currentCombo.FoodTastes.length > 0) {
        if (isPlus) {
          this.comboGroup = currentCombo;
          currentCombo.IsShow = true;
        }
      } else {
        if (isPlus) {
          this.cartItem.Combos.push({ ...currentCombo.Combo });
        } else {
          let index = this.cartItem.Combos.findIndex((combo) => {
            return combo.ComboId == tgId;
          });
          this.cartItem.Combos.splice(index, 1);
        }
        let qty = this.changeQtyBtn(tgId);
        if (qty == 0) {
          if (currentCombo.FoodTastes.length == 0) {
            document
              .querySelector(
                `.combo-counter[data-combo="${currentCombo.Combo.ComboId}"]`
              )
              .remove();
            let index = this.comboIds.findIndex((id) => {
              return id == currentCombo.Combo.ComboId;
            });
            this.comboIds.splice(index, 1);
          }
        }
      }
      this.calculate();
    },
    editSelectedItem(e) {
      let tg = e.currentTarget;
      let foodCombo = this.item.FoodCombos.find((combo) => {
        return combo.ComboKind.ComboKindId == tg.dataset.combokind;
      });
      let editCombo = foodCombo.Combos.find((combo) => {
        return combo.Combo.ComboId == tg.dataset.combo;
      });
      let selectedCombo = this.cartItem.Combos.find((combo) => {
        return (
          combo.ComboId == tg.dataset.combo && combo.Count == tg.dataset.count
        );
      });
      this.selectedCombo = selectedCombo;
      this.comboGroup = editCombo;
      editCombo.IsShow = true;
    },
    removeSelectedItem(e) {
      let tg = e.currentTarget;
      let targetIndex = this.cartItem.Combos.findIndex((combo) => {
        return (
          combo.ComboId == tg.dataset.combo &&
          combo.ComboKindId == tg.dataset.combokind &&
          combo.Count == tg.dataset.count
        );
      });
      let reCount = this.cartItem.Combos[targetIndex].Count;
      this.cartItem.Combos.splice(targetIndex, 1);
      this.cartItem.Combos.forEach((combo) => {
        if (
          combo.ComboId == tg.dataset.combo &&
          combo.ComboKindId == tg.dataset.combokind &&
          combo.Count > reCount
        ) {
          let div = document.querySelector(`[data-id="${combo.ComboId}"]`);
          let afterDiv = div.querySelector(
            `.selected-combo[data-count="${combo.Count}"]`
          );
          afterDiv.dataset.count = combo.Count - 1;
          afterDiv.querySelector(".remove-selected-item").dataset.count =
            combo.Count - 1;
          afterDiv.querySelector(".order-combo-taste-edit").dataset.count =
            combo.Count - 1;
          combo.Count = combo.Count - 1;
        }
      });
      let cartCombos = this.cartItem.Combos.filter((combo) => {
        return combo.ComboId == tg.dataset.combo;
      });
      let comboCounter = document.querySelector(
        `.combo-counter[data-combo="${tg.dataset.combo}"]`
      );
      if (cartCombos.length > 0) {
        comboCounter.querySelector(".combo-counter-qty").innerHTML =
          cartCombos.length;
      } else {
        if (comboCounter) {
          comboCounter.remove();
        }
        let tgIndex = this.comboIds.findIndex((id) => {
          return id == tg.dataset.combo;
        });
        this.comboIds.splice(tgIndex, 1);
      }
      document
        .querySelector(
          `.selected-combo[data-combo="${tg.dataset.combo}"][data-count="${tg.dataset.count}"]`
        )
        .remove();
      this.$nextTick(() => this.changeQtyBtn());
    },
    getComboFoodTaste(id) {
      const index = this.cartItem.Combos.findIndex(
        (item) => item.ComboId == id
      );
      if (index < 0) return undefined;
      const combo = this.cartItem.Combos[index];
      const foodTaste = combo.FoodTaste;
      if (foodTaste == undefined || foodTaste.length == 0) return undefined;
      else return foodTaste;
    },
    qtyAdd() {
      this.cartItem.Qty = this.cartItem.Qty + 1;
      this.calculate();
    },
    qtyMinus() {
      if (this.cartItem.Qty <= 1) {
        this.$swal(this.$t("order.minqtyreached"));
        return;
      }
      this.cartItem.Qty = this.cartItem.Qty - 1;
      this.calculate();
    },
    calculate() {
      this.$cookie.cart.calculateCombo(this.cartItem);
      this.currentSumTotal = this.cartItem.SumTotal;
    },
    saved() {
      let canSave = true;
      this.item.FoodCombos.forEach((foodComboGroup) => {
        if (foodComboGroup.FoodCombo.MinQty > 0) {
          let num = 0;
          this.cartItem.Combos.forEach((combo) => {
            if (combo.ComboKindId == foodComboGroup.ComboKind.ComboKindId) {
              num = num + 1;
            }
          });
          if (num < foodComboGroup.FoodCombo.MinQty) {
            canSave = false;
            return;
          }
        }
      });

      if (!canSave) {
        this.$swal();
        return;
      }

      let combosHaveRequiredTaste = [];
      this.item.FoodCombos.forEach((foodCombo) => {
        foodCombo.Combos.forEach((combo) => {
          let haveRequiredTaste = combo.FoodTastes.some((foodTaste) => {
            return foodTaste.MinQty > 0;
          });
          if (haveRequiredTaste) {
            combosHaveRequiredTaste.push(combo);
          }
        });
      });
      let checkTasteCombos = this.cartItem.Combos.filter((combo) => {
        let result = combosHaveRequiredTaste.some((requiredCombo) => {
          return requiredCombo.Combo.ComboId == combo.ComboId;
        });
        return result;
      });
      let allComboTasteExist = checkTasteCombos.every((cartCombo) => {
        let requiredTasteExist = combosHaveRequiredTaste.some(
          (requiredCombo) => {
            if (requiredCombo.Combo.ComboId == cartCombo.ComboId) {
              let tasteExist = requiredCombo.FoodTastes.every((foodTaste) => {
                if (foodTaste.MinQty > 0) {
                  let exist = cartCombo.Tastes.some((taste) => {
                    return taste.TasteKindId == foodTaste.TasteKind.TasteKindId;
                  });
                  return exist;
                } else {
                  return true;
                }
              });
              return tasteExist;
            } else {
              return false;
            }
          }
        );
        return requiredTasteExist;
      });

      if (!allComboTasteExist) {
        this.$swal(this.$t("order.flavorsnotselected"));
        return;
      }

      this.item.FoodTastes.forEach((foodTaste) => {
        if (foodTaste.MinQty > 0) {
          let num = 0;
          this.cartItem.Tastes.forEach((taste) => {
            if (taste.TasteKindId == foodTaste.TasteKind.TasteKindId) {
              num = num + 1;
            }
          });
          if (num < foodTaste.MinQty) {
            canSave = false;
            return;
          }
        }
      });
      if (!canSave) {
        this.$swal(this.$t("order.flavorsnotselected"));
        return;
      }

      this.cartItem.Combos.forEach((combo) => {
        combo.Qty = 1;
        combo.OriginalPrice = combo.Price;
      });
      this.$emit("saved", this.cartItem);
    },
    closed() {
      this.$emit("closed");
    },
    tasteChange(tastes) {
      this.cartItem.Tastes = tastes;
    },
    comboClick(comboGroup, e) {
      //當前品項已重複選的數量
      const checkComboQty = this.cartItem.Combos.filter((item) => {
        return item.ComboId == comboGroup.Combo.ComboId;
      });
      //當前套餐群組的資訊
      let checkComboGroup = this.item.FoodCombos.find((combo) => {
        return combo.ComboKind.ComboKindId == comboGroup.Combo.ComboKindId;
      });
      //當前套餐群組內已選品項數量總和
      let checkComboGroupQty = this.cartItem.Combos.filter((combo) => {
        return combo.ComboKindId == checkComboGroup.ComboKind.ComboKindId;
      });

      //若當前品項尚未選擇過
      if (checkComboQty.length == 0) {
        //當前套餐的最大可選數為1時
        if (checkComboGroup.FoodCombo.MaxQty == 1) {
          if (comboGroup.FoodTastes.length > 0) {
            e.preventDefault();
            comboGroup.IsShow = true;
          } else {
            if (checkComboGroupQty.length > 0) {
              let item = this.cartItem.Combos.find((combo) => {
                return comboGroup.Combo.ComboKindId == combo.ComboKindId;
              });
              let itemIndex = this.cartItem.Combos.findIndex((combo) => {
                return item.ComboId == combo.ComboId;
              });
              let idIndex = this.comboIds.findIndex((id) => {
                return item.ComboId == id;
              });
              let selectedCombos = document.querySelectorAll(
                `.selected-combo[data-combo="${item.ComboId}"]`
              );
              this.cartItem.Combos.splice(itemIndex, 1);
              this.comboIds.splice(idIndex, 1);
              for (let i = 0; selectedCombos.length > i; i++) {
                selectedCombos[i].remove();
              }
            }
            comboGroup.Count = 1;
            this.cartItem.Combos.push({ ...comboGroup.Combo });
          }
        } else {
          if (checkComboGroup.FoodCombo.MaxQty == checkComboGroupQty.length) {
            e.preventDefault();
            this.$swal(this.$t("order.mealsetsover"));
            return;
          }
          if (comboGroup.FoodTastes.length > 0) {
            e.preventDefault();
            comboGroup.IsShow = true;
          } else {
            let newCombo = { ...comboGroup.Combo };
            newCombo.Count = 1;
            this.cartItem.Combos.push(newCombo);
            this.calculate();
            this.$nextTick(() => {
              this.setSelectedCombo(newCombo);
            });
          }
        }
      } else {
        if (comboGroup.FoodTastes.length > 0) {
          e.preventDefault();
          this.$swal({
            title: this.$t("order.suretoreset"),
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#16418f",
            cancelButtonColor: "#ff5b5b",
            confirmButtonText: this.$t("order.ok"),
            cancelButtonText: this.$t("order.cancel"),
          }).then((result) => {
            if (result.isConfirmed) {
              let newCombos = this.cartItem.Combos.filter((combo) => {
                return comboGroup.Combo.ComboId != combo.ComboId;
              });
              this.cartItem.Combos = newCombos;
              let idIndex = this.comboIds.findIndex((id) => {
                return comboGroup.Combo.ComboId == id;
              });
              let counter = document.querySelector(
                `.combo-counter[data-combo="${comboGroup.Combo.ComboId}"]`
              );
              let selectedCombos = document.querySelectorAll(
                `.selected-combo[data-combo="${comboGroup.Combo.ComboId}"]`
              );
              this.comboIds.splice(idIndex, 1);
              if (counter) {
                counter.remove();
              }
              for (let i = 0; selectedCombos.length > i; i++) {
                selectedCombos[i].remove();
              }
              this.changeQtyBtn();
            }
          });
        } else {
          let newCombos = this.cartItem.Combos.filter((combo) => {
            return comboGroup.Combo.ComboId != combo.ComboId;
          });
          this.cartItem.Combos = newCombos;
          let idIndex = this.comboIds.findIndex((id) => {
            return comboGroup.Combo.ComboId == id;
          });
          let counter = document.querySelector(
            `.combo-counter[data-combo="${comboGroup.Combo.ComboId}"]`
          );
          this.comboIds.splice(idIndex, 1);
          if (counter) {
            counter.remove();
          }
          this.changeQtyBtn();
        }
      }
    },
    comboAdd(combo) {
      //套餐項目單選時移除其他已選項目
      let foodCombo = this.item.FoodCombos.find((fc) => {
        return fc.ComboKind.ComboKindId == combo.ComboKindId;
      });
      let removeCombo = this.cartItem.Combos.find((c) => {
        return c.ComboKindId == foodCombo.ComboKind.ComboKindId;
      });
      if (foodCombo.FoodCombo.MaxQty == 1 && removeCombo) {
        let selectedCombo = document.querySelector(
          `.selected-combo[data-combo="${removeCombo.ComboId}"]`
        );
        if (selectedCombo) {
          selectedCombo.remove();
        }

        let comboIndex = this.cartItem.Combos.findIndex((c) => {
          return c.ComboKindId == foodCombo.ComboKind.ComboKindId;
        });
        let idIndex = this.comboIds.findIndex((id) => {
          return id == removeCombo.ComboId;
        });
        this.cartItem.Combos.splice(comboIndex, 1);
        this.comboIds.splice(idIndex, 1);
      }

      let checkComboCount = this.cartItem.Combos.filter((cartCombo) => {
        return combo.ComboId == cartCombo.ComboId;
      });
      if (checkComboCount.length == 0) {
        this.comboIds.push(combo.ComboId);
      }
      let newCombo = { ...combo };
      newCombo.Count = checkComboCount.length + 1;
      this.cartItem.Combos.push(newCombo);
      this.changeQtyBtn();
      this.calculate();
      this.$nextTick(() => {
        this.setSelectedCombo(newCombo);
      });
    },
    comboEdit(combo) {
      let index = this.cartItem.Combos.findIndex((cartCombo) => {
        return (
          combo.ComboId == cartCombo.ComboId && combo.Count == cartCombo.Count
        );
      });
      this.cartItem.Combos[index].Tastes = combo.Tastes;
      this.calculate();
      let comboKind = document.querySelector(
        `.order-item[data-id="${this.cartItem.Combos[index].ComboId}"]`
      );
      let tgCombo = comboKind.querySelector(
        `.selected-combo[data-count="${this.cartItem.Combos[index].Count}"]`
      );
      if (this.cartItem.Combos[index].FoodTaste) {
        tgCombo.querySelector(".order-combo-taste-text").innerHTML =
          this.cartItem.Combos[index].FoodTaste;
      } else {
        tgCombo.querySelector(".order-combo-taste-text").innerHTML = this.$t(
          "order.modifyflavors"
        );
      }
    },
    comboClose(comboGroup) {
      this.selectedCombo = undefined;
      comboGroup.IsShow = false;
    },
  },
  mounted() {},
  created() {
    // window.scrollTo(0, 0);
  },
};
</script>
