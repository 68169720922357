import * as tradeStatusType from "./tradeStatusType";
import * as onlineOrderStatusType from "./onlineOrderStatusType";
import * as countryCodeType from "./countryCodeType";
import * as langType from "./langType";
import * as weekType from "./weekType";
import * as eatType from "./eatType";

export default {
  tradeStatusType,
  onlineOrderStatusType,
  countryCodeType,
  langType,
  weekType,
  eatType,
};
