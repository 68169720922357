const en = {
  menu: {
    openinghours: "Opening Hours",
    login: "Login",
    joinstore: "Join Membership",
    open: "Open",
    closed: "Closed",
    preorder: "Pre-order",
    left: "Left ",
    soldout: "Sold Out",
    cart: "Cart",
    cartitem: " Item",
    pleasechooseyourmeal: "Please choose your meal",
    closedday: "Weekly Day Off",
    today: "Today",
    stocklimitreached: "Stock Limit Reached",
    closedtoday: "Closed Today",
    ordertimehaspassed: "Order Time Has Passed",
    thistimeslotisfullybooked: "This Time Slot Is Fully Booked",
    minuteslater: "Minutes Later",
    hourslater: "Hours Later",
    orderedmeals: "Meals Ordered",
    addmoremeals: "Add More Meals",
    takeout: "Take Out",
    checkmeals: "Confirm Order Items",
    orderedcounts: "Added Item Quantity",
  },
  week: {
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
  },
  takeAwayTime: {
    expectedpickuptime: "Expected Pickup Time",
    pickupdate: "Pickup Date",
    pickuptime: "Pickup Time",
    otherdates: "Other Dates",
    confirm: "Confirm",
    today: "Today",
    orderfull: "reached",
    isorderfull: " (this time slot has been reached)",
  },
  checkout: {
    cart: "Cart",
    expectedpickuptime: "Expected Pickup Time",
    cancel: "Cancel",
    notice: "Notice",
    orderdetails: "Order Details",
    total: "Total",
    memo: "Notes",
    member: "Member",
    name: "Name",
    phonenumber: "Phone Number",
    receipt: "Receipt",
    uniformnumber: "Uniform Number",
    vehiclenumber: "Vehicle Number",
    submitorder: "Submit Order",
    today: "Today",
    closedtoday: "Closed Today",
    thistimeslotisfullybooked: "This Time Slot Is Fully Booked",
    ordertimehaspassed: "Order Time Has Passed",
    allergiesoringredients:
      "If you have any allergies or ingredients you don't want to be added, please let us know.",
    vehiclenumberisincorrect: "The vehicle number format is incorrect.",
    areyousuretosubmittheorder: "Are you sure you want to submit the order?",
    businesshourshavechanged:
      "Business hours have changed. Please check the time again.",
    areyousureyouwanttodeletethis: "Are you sure you want to delete this?",
    areyousureyouwanttodeletetheentireorder:
      "Are you sure you want to delete the entire order?",
    thedatehasbeenupdated: "The date has been updated.",
    minuteslater: "Minutes Later",
    hourslater: "Hours Later",
    continueordering: "Continue Ordering",
    paymentmethod: "Payment Method",
    payatstore: "Pay at Store",
    paymentconfirming: "Payment Confirming",
    paymentcompleted: "Payment Completed",
    paymentfailed: "Payment Failed",
  },
  login: {
    login: "Login",
    phonenumber: "Phone Number",
    password: "Password",
    forgotpassword: "Forgot Password",
    register: "Register",
  },
  join: {
    join: "Join",
    joinstore: "Join Membership",
    alreadyjoin: "You are already logged in. You can join directly.",
  },
  forgotPassword: {
    forgotpassword: "Forgot Password",
    phonenumber: "Phone Number",
    getverifycode: "Get Verify Code",
    retry: "s Retry",
    verifycode: "Verify Code",
    newpassword: "New Password",
    enterpasswordagain: "Enter Password Again",
    pleaseentersamepassword: "Please Enter Same Password",
    save: "Save",
    pleasetryagainlater: "Please Try Again Later",
    pleaseenterphonenumber: "Please Enter Phone Number",
    pleasecheckyourmobilemessage: "Please Check Your Mobile Message",
  },
  member: {
    profile: "Profile",
    historyorder: "Order History",
    changepassword: "Change Password",
    reportissue: "Report Issue",
    logout: "Logout",
    name: "Name",
    birthday: "Birthday",
    email: "Email",
    address: "Address",
    link: "Link Accounts",
    unlink: "Unlink Accounts",
    notlink: "Not Linked",
    save: "Save",
    changesuccess: "Change Success",
    areyousureyouwanttounlink: "Are you sure you want to unlink?",
    unlinkseccessful: "Unlink Successful",
  },
  historyOrder: {
    historyorder: "Order History",
    ordernumber: "Order Number",
    createtime: "Order Time",
    eattype: "Meal Type",
    reservetime: "Pick-Up Time",
    checkouttime: "Checkout Time",
    memo: "Notes",
    total: "Total",
    confirming: "Confirming",
    accepted: "Accepted",
    rejected: "Rejected",
    completed: "Completed",
    pickself: "Pickup",
    instore: "Dine-in",
    takeout: "Takeout",
    delivery: "Delivery",
  },
  order: {
    required: "Required",
    available: "Available",
    exceedsselectionlimit: "Exceeds selection limit",
    minqtyreached: "Minimum Quantity Reached",
    ok: "OK",
    cancel: "Cancel",
    mealsetsnotselected: "Meal Sets Not Fully Selected",
    flavorsnotselected: "Flavors Not Fully Selected",
    mealsetsover: "Meal Sets Exceed Selection Limit",
    suretoreset: "This item will be reset. Are you sure you want to continue?",
    modifyflavors: "Modify Flavors",
    save: "Save",
    soldout: "Sold Out",
    memo: "Notes",
  },
  trade: {
    orderinformation: "Order Information",
    orderdetail: "Order Detail",
    ordernumber: "Order Number",
    confirming: "Confirming",
    accepted: "Accepted",
    rejected: "Rejected",
    completed: "Completed",
    tablenumber: "Table Number",
    tablenum: "Table Number",
    name: "Name",
    memo: "Notes",
    total: "Total",
    receiptinformation: "Receipt Information",
    uniformnumber: "Uniform Number",
    vehiclenumber: "Vehicle Number",
    backtomenu: "Back To Menu",
    rejectreason: "Reject Reason",
    completetime: "Complete Time",
    createtime: "Order Time",
    reservetime: "Pick-Up Time",
    member: "Member",
    changelogs: "Change Logs",
    scanqrcodetocheck: "Please present this QR Code at checkout.",
  },
  changePassword: {
    changepassword: "Change Password",
    oldpassword: "Old Password",
    newpassword: "New Password",
    enterpasswordagain: "Enter Password Again",
    pleaseentersamepassword: "Please Enter Same Password",
    changesuccess: "Change Success",
    save: "Save",
  },
  register: {
    memberregister: "Member Register",
    name: "Name",
    phonenumber: "Phone Number",
    verifycode: "Verify Code",
    password: "Password",
    enterpasswordagain: "Enter Password Again",
    pleaseentersamepassword: "Please Enter Same Password",
    getverifycode: "Get Verify Code",
    retry: "s Retry",
    register: "Register",
    alreadyhaveanaccount: "Already have An Account",
    pleasetryagainlater: "Please Try Again Later",
    pleaseenterphonenumber: "Please Enter Phone Number",
    pleasecheckyourmobilemessage: "Please Check Your Mobile Message",
  },
  feedback: {
    reportissue: "Report Issue",
    pleaseentertheissueyouwanttoreport:
      "Please enter the issue you want to report",
    submit: "Submit",
    thanksforyourreport: "Thanks For Your Report",
  },
  error: {
    sorrymessage:
      "Sorry, the page you are looking for does not exist or has been deleted.",
    goback: "Go Back To The Previous Page",
    urlexpired: "This URL has expired.",
  },
};

export default en;
